import React, { useState, useContext, useEffect } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  paginationLoading,
  customStyle,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
import Select from "react-select";
//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const IngredientLimitAction = () => {
  const { t } = useTranslation();
  const history = useHistory();

  let {
    loading,
    setLoading,
    //item limit
    branchForSearch,
    ingredientItemForSearch,
    
  } = useContext(RestaurantContext);

  const {id} = useParams()

  const [newIngredientLimit, setNewIngredientLimit]  = useState({
    edit: false,
    id:null,
    branch: null,
    items: null,
  })

   //set edit true & values
   const handleSetEdit = async() => {
    try{
      const url = `${BASE_URL}/settings/get-ingredient_item-limit/${id}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      const ingredientItemLimit = response.data;
      const branch = {
        id: ingredientItemLimit[0].branch_id,
        name: ingredientItemLimit[0].branch_name,
      }
      setNewIngredientLimit({
        ...newIngredientLimit,
        id: parseInt(id),
        branch: branch,
        items: ingredientItemLimit,
        edit: true,
      });
    }catch (err){
      toast.error("please try again",{
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      })
    }

  };

  useEffect(()=>{

    if(id){
      handleSetEdit()
    }else{
      const items = [];
      ingredientItemForSearch.forEach((item) => {
        const { id, name } = item;
        if (parseInt(item.allow_multi_quantity)) {
          items.push({
            id: id,
            name: name,
            min_limit: 0,
            max_limit: 0,
          });
        }
      });
      setNewIngredientLimit({
          ...newIngredientLimit,
          items: items
      })
    }

  },[ingredientItemForSearch])
 
  const handleSetBranch = (branch) =>{
    setNewIngredientLimit({
        ...newIngredientLimit,
        branch: branch,
    });
  }
  
  const handleQty =(e,index)=>{
    const {name, value} = e.target;
    const updatedItems = [...newIngredientLimit.items];

    // Update only the specific item at the given index
    updatedItems[index] = {
      ...updatedItems[index],
      [name]: value,
    };
    setNewIngredientLimit({
        ...newIngredientLimit,
        items: updatedItems
    })
  }

  const handleSaveNewLimit = (e)=> {
    e.preventDefault();
    const url = "/settings/ingredient_item-limit-storeUpdate";
    const limithUrl = BASE_URL + url;
    let formData = new FormData();
    if(id){
        formData.append("id", newIngredientLimit.id);
    }

    formData.append("branch_id", newIngredientLimit.branch.id);
    formData.append("branch_name", newIngredientLimit.branch.name);
    formData.append("items", JSON.stringify(newIngredientLimit.items));
    setLoading(true)
    return axios
    .post(limithUrl, formData, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
    
      setNewIngredientLimit({
        edit: false,
        id:null,
        branch: null,
        items: null,
      });
      
      setLoading(false);
      if(id){
        toast.success(`${_t(t("Ingredient Limit has been updated"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
      }else{
        toast.success(`${_t(t("Ingredient Limit has been added"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
      }
      
      history.push("/dashboard/manage/stock/ingredient-limit");     
    })
    .catch((error) => {
      setLoading(false);
      if(error.response?.data && error.response?.data?.errors?.branch_name[0]){
        toast.error(`${_t(t(error.response.data.errors.branch_name[0]))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }else{
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    });
  }


  return (
    <>
          <Helmet>
            <title>{_t(t("Ingredient limit"))}</title>
          </Helmet>
    
          {/* Add modal */}          
          {/* Add modal Ends*/}
    
          {/* main body */}
          <main id="main" data-simplebar>
            <div className="container-fluid">
              <div className="row t-mt-10 gx-2">
                {/* left Sidebar */}
                <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                  <ManageSidebar />
                </div>
                {/* left Sidebar ends */}
    
                {/* Rightbar contents */}
                <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                  <div className="t-bg-white">
                    <div className="fk-scroll--pos-menu" data-simplebar>
                      <div className="t-pl-15 t-pr-15">
                        {/* Loading effect */}
                        {loading === true ? (
                          tableLoading()
                        ) : (
                          <div key="fragment3">
                            {/* next page data spin loading */}
                            {/* <div className={`${dataPaginating && "loading"}`}></div> */}
                            {/* spin loading ends */}
    
                            <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                              <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                <ul className="t-list fk-breadcrumb">
                                  <li className="fk-breadcrumb__list">
                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {!newIngredientLimit.edit
                                    ? _t(t("Add new ingredient limit"))
                                    : _t(t("Update ingredient limit"))}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-6 col-lg-7">
                                <div className="row gx-3 align-items-center">                                  
                                </div>
                              </div>
                            </div>
                            {/* form start here */}
                            <div key="fragment-branch-1">
                              <form
                                onSubmit={handleSaveNewLimit}
                                className="row card p-2 mx-3 mb-5 new-text-style"
                                autoComplete="off"
                              >
                                <div className="row">
                                    <div className="form-group col-4 mt-3">
                                        <label htmlFor="name" className="form-label">
                                            {_t(t("Branch"))}{" "}
                                            <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          value={newIngredientLimit.branch}
                                          options={branchForSearch ? branchForSearch : []}
                                          getOptionLabel={(option) => option.name}
                                          getOptionValue={(option) => option.id}
                                          className="basic-multi-select table-text"
                                          classNamePrefix="select"
                                          styles={customStyle}
                                          onChange={handleSetBranch}
                                        />
                                    </div>                            
                                </div>
            
                                <div className="row">
                                <div className="form-group mt-3 col-12">
                                    <div className="table-responsive mt-3 pr-1" style={{maxHeight:"17rem",overflow: "auto"}}>
                                        <table className="table table-bordered-sm table-hover">
                                            <thead className="align-middle">
                                                <tr>
                                                    <th
                                                      className="text-capitalize align-middle text-center"
                                                    >
                                                        {_t(t("Item Name"))}
                                                    </th>
                                                    <th
                                                      className="text-capitalize align-middle text-center"
                                                    >
                                                        {_t(t("Min Limit"))}
                                                    </th>
                                                    <th
                                                      className="text-capitalize align-middle text-center"
                                                    >
                                                        {_t(t("Max Limit"))}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="align-middle">
                                                {
                                                    newIngredientLimit?.items && newIngredientLimit.items.map((item,index)=>{
                                                        return (
                                                            <tr>
                                                                <td
                                                                  className="table-text text-capitalize align-middle text-center"
                                                                >
                                                                    {item.name}
                                                                </td>
                                                                <td
                                                                  className="table-text text-capitalize align-middle text-center"
                                                                >
                                                                    <input 
                                                                        class="form-control rounded-sm text-center"
                                                                        type="number"
                                                                        onKeyDown={(evt) =>
                                                                        ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                                                        evt.preventDefault()
                                                                        }
                                                                        onWheel={(e) => e.target.blur()}  
                                                                        min="0"
                                                                        max={item.max_limit}
                                                                        name="min_limit"
                                                                        value={item.min_limit}
                                                                        placeholder="min limit"
                                                                        required
                                                                        onChange={(e) => handleQty(e,index)}
                                                                    />
                                                                </td>
                                                                <td
                                                                  className="table-text text-capitalize align-middle text-center"
                                                                >
                                                                    <input 
                                                                        class="form-control rounded-sm text-center"
                                                                        type="number"
                                                                        onKeyDown={(evt) =>
                                                                        ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                                                        evt.preventDefault()
                                                                        }
                                                                        onWheel={(e) => e.target.blur()}  
                                                                        min="0"
                                                                        name="max_limit"
                                                                        placeholder="max limit"
                                                                        value={item.max_limit}
                                                                        required
                                                                        onChange={(e) => handleQty(e,index)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                </div>
                   
                                <div className="row mt-4">
                                  <div className="d-flex justify-content-end">
                                    <div className="col-3 d-flex justify-content-end">
                                      <button
                                        type="button"
                                        className="btn rounded hov-btn mr-3"
                                        onClick={()=>(history.push("/dashboard/manage/stock/ingredient-limit"))}
                                      >
                                        <i className="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                      </button>
                                      <button
                                        type="submit"
                                        className="btn btn-primary rounded"
                                      >
                                        <i className="fa fa-floppy-o" aria-hidden="true"></i> {!newIngredientLimit.edit ? _t(t("Save")) : _t(t("Update"))}
                                      </button>
                                    </div>
                                  </div>
                                </div>  
                              </form>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Rightbar contents end*/}
              </div>
            </div>
          </main>
          {/* main body ends */}
        </>
  )
}

export default IngredientLimitAction
