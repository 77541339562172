import React, {useState} from 'react';
import {
    _t,
    deleteCookie
  } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
  

const BuildModal = ({
    restaurantModal,
    setRestaurantModal,
}) => {
    const { t } = useTranslation();

    const [ bellRing, setBellRing] = useState(localStorage.getItem('build-update-bell-ring') || false);

    // handle close modal
    const handleBellRingChange = () => {
        setRestaurantModal(false)
        setBellRing(true);
        localStorage.setItem('build-update-bell-ring', true);
    }

  return (
    <>
    {/* bell ring animation */}
    {
        bellRing && (
            <div className='position-relative'>
                <div className="bell" onClick={()=>{
                    localStorage.clear();
                    deleteCookie();
                    setRestaurantModal(false)
                }}>
                    <div className="bell-border"></div>
                    <i className="fa fa-bell btn-bell"></i>
                </div>
            </div>    
        )
    }
    {/* build update modal issue */}
    {
        restaurantModal && (
            <div
            className={`modal fade ${restaurantModal ? "showing" : ""}`}
            aria-hidden="true"
            >
                <div className="modal-dialog modal-md">
                    <div className="modal-content" style={{ borderRadius: "1rem" }}>
                        <div className="modal-body py-4">
                            <div className="card card-body">
                                <h2 className='text-center'>{_t(t("Hurray! 🎉"))}</h2>
                                <p className="text-center">{_t(t("A new version update is available. Please clear your cache to apply the update."))}</p>
                                <div className="d-flex justify-content-center mb-3 rounded-sm">
                                    <button className="btn btn-success mr-4" onClick={()=>handleBellRingChange()}>
                                        {_t(t("Cancel"))}
                                    </button>
                                    <button
                                        className="btn btn-primary rounded-sm"
                                        onClick={() => {
                                            localStorage.clear();
                                            deleteCookie();
                                            setRestaurantModal(false)
                                        }}
                                    >
                                        {_t(t("Flush Cache"))}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        )
    }
      
    </>
  )
}

export default BuildModal
