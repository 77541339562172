import React from 'react';
import {
    _t,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next"; 

const WaiterChangeModal = ({changeWaiter, setChangeWaiter, handleWaiterChangeApi}) => {
    const { t } = useTranslation();

    const handleSetWaiter = (waiter) =>{
        setChangeWaiter({
            ...changeWaiter,
            waiterSelected: changeWaiter.waiterSelected?.id === waiter?.id ? null : waiter,
        })
    }

    const handleSearchWaiter = (e) =>{
        const search = e.target.value.toLowerCase();
        if(search?.length){
            const searchedWaiter = changeWaiter.waiters && changeWaiter.waiters.filter((waiter) => waiter.name.toLowerCase().includes(search));
            setChangeWaiter({
                ...changeWaiter,
                searchWaiter: searchedWaiter,
            })
        }else{
            setChangeWaiter({
                ...changeWaiter,
                searchWaiter: null,
            })
        }
    }
    const waitersList = changeWaiter.searchWaiter || changeWaiter.waiters;

  return (
    <>
        <div
        className={`modal fade ${
            changeWaiter.modal !== false ? "showing" : ""
        }`}
        id="addReason"
        aria-hidden="true"
        >
            <div className="modal-dialog modal-md my-auto">
                <div className="modal-content">
                 <div className="modal-header align-items-center">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                    <div className = "rider-text">
                     Change Waiter
                    </div>
                    <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                        setChangeWaiter({
                            waiterSelected: null,
                            id: null,
                            modal:false,
                            searchWaiter: null,
                            waiters: null,
                        })
                    }}
                    ></button>
                    </div>
                 </div>
                 <div className="modal-body">
                    {/* show form or show saving loading */}
                    <div key="fragment-food-group-1">
                        <form
                         className="my-3 rounded-lg"
                         onSubmit={(e)=>handleWaiterChangeApi(e)}
                         autoComplete="off"
                         >
                            <div className="col-12">
                                <label
                                for="recipient-name"
                                className="col-form-label fw-bold class-variants "
                                >
                                 Waiter
                                </label>
                                <div style={{margin:"0px 1.27rem 0.6rem 0.77rem"}}>
                                    <input
                                        className="form-control table-text rounded-sm"
                                        placeholder='search customer'
                                        onChange={(e)=>handleSearchWaiter(e)}
                                    />
                                </div>
                                <div className="mb-1 modal-scroll">
                                    <div  className="row m-0">
                                        {waitersList && waitersList.map((waiter) =>{
                                            return (
                                                <div className="col-6 mb-1 text-break">
                                                    <button type="button" className={`w-100 btn btn-sm ${waiter.id === changeWaiter.waiterSelected?.id ? "btn-success" : "btn-primary"} table-text`} onClick={()=> handleSetWaiter(waiter)}>
                                                     {waiter.name}
                                                    </button>
                                                </div>
                                            )
                                          })
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="pb-2 pl-2 mt-3 d-flex justify-content-center mb-2">
                                <button
                                    className="btn btn-sm btn-success text-center px-3 text-uppercase"
                                    type="submit"
                                >
                                    {_t(t("Save"))}
                                </button>
                            </div>
                        </form>
                    </div>
                 </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default WaiterChangeModal
