import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { chartThemeColorsRadial } from "./index";


const RadialWidget = ({ data, heading, handleReq=null, url=null, loading=false }) => {
    const [formatData, setFormatData] = useState(null);
     const [total, setTotal] = useState(0);

      useEffect(() => {
        if(data){
          setFormatData(data);
          setTotal(data.reduce((acc, item) => acc + parseFloat(item.value), 0));
        }else{
          setFormatData(null)
        }
      }, [data]);
    
  return (
    <div className="col col-4 row mx-0 mt-3 px-2 position-relative">
      <div className="row px-0 mx-0 justify-content-between col col-12 ">
        <div className="dashboard__card col col-12 w-100 flex-basis-100">
          {heading ? <h5 className="mb-1 mt-0">{heading}</h5> : ""}
          <div className="d-flex flex-col mx-3 my-2" style={{ gap: "1rem" }}>
          </div>
          {formatData ? ( 
            <Chart
              className="radialChart"
              width="100%"
              height="300px"
              series= {formatData.map((x) => parseFloat(parseFloat(x.value)?.toFixed(2)))}
              type="radialBar"
              options={{
                chart: {
                  type: "radialBar",
                  height: "280px",
                  width:"200px",
                  toolbar:{show:false},
                },
                plotOptions: {
                  bar: {
                    distributed: true,
                  },
                  range:{
                    min: 0,
                    max: Math.max(...formatData.map((item) => item.value)),
                  },
                  radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 270,
                    inverseOrder: false,
                    hollow: {
                      margin: 5,
                      size: '50%',
                      background: 'transparent',
                    },
                    dataLabels: {
                      show:true,
                      total: {
                        show: true,
                        formatter: (w) => (total ? total.toFixed(2) : 0),
                      },
                      value: {
                        formatter: function (value) {
                          return value; // This displays the value without percentage symbol
                        },
                      },
                    },
                  
                  }
                },
                colors: chartThemeColorsRadial,
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  lineCap: 'round'
                },
                labels:formatData.map((x) => x.name),
                tooltip: {/*
                  enabled: true,
                  custom: ({ series, seriesIndex, dataPointIndex }) =>
                    `<div style="color:white;background-color:${chartThemeColorsRadial[seriesIndex]};" className="p-2">
                <p>${
                    series[seriesIndex]
                    ? parseFloat(series[seriesIndex])?.toFixed(2)
                    : parseFloat(formatData[seriesIndex].value)
                }</p>
                </div>`,
              */},
                legend: {
                  show: true,
                  floating: true,
                  fontSize: '14px',
                  position: 'left',
                  offsetX: -30,
                  offsetY: -10,
                  labels: {
                    useSeriesColors: true,
                  },
                  markers: {
                    size: 0,
                  },
                  formatter: function(seriesName, opts) {
                    return seriesName 
                  },
                  itemMargin: {
                    vertical: 3
                  }
                },
               }}
            />
          ):(
              <div className="btn_load btn_load_radial">
                {
                  loading ? (
                  <>
                    {/*loading */}
                    <div className={`${(loading) && "loadingBlur"}`}></div>
                    <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </>
                  ):(
                    <button className="btn btn-sm btn-primary rounded-md" onClick={()=>url && handleReq(url)}>Load Data</button>                
                  )
                }
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default RadialWidget
