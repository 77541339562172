import React, { useState, useEffect } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

//functions
import { getCookie, getSystemSettings } from "../functions/Functions";
import Cookies from "universal-cookie";
//3rd party packages

//creating context api
const SettingsContext = React.createContext();

const SettingsProvider = ({ children }) => {
  const cookies = new Cookies();
  // State hooks  here
  //loading
  const [loading, setLoading] = useState(false);
  const [dataPaginating, setDataPaginating] = useState(false);

  //permission group
  const [permissionGroup, setPermissionGroup] = useState(null);
  const [permissionGroupForSearch, setPermissionGroupForSearch] =
    useState(null);

  //permissions
  const [permissions, setPermissions] = useState(null);

  //languages
  const [languageList, setLanguageList] = useState(null);
  const [navLanguageList, setNavLanguageList] = useState(null);
  const [languageListForSearch, setLanguageListForSearch] = useState(null);

  //currencies
  const [currencyList, setCurrencyList] = useState(null);
  const [navCurrencyList, setNavCurrencyList] = useState(null);
  const [currencyListForSearch, setCurrencyListForSearch] = useState(null);

  //settings
  const [generalSettings, setGeneralSettings] = useState(null);

  //email
  const [emailSearch,setEmailSearch] =useState(null);
  const [pageEmail,setPageEmail] = useState(null)

  //smtp
  const [smtp, setSmtp] = useState({
    MAIL_MAILER: null,
    MAIL_HOST: null,
    MAIL_PORT: null,
    MAIL_USERNAME: null,
    MAIL_PASSWORD: null,
    MAIL_ENCRYPTION: null,
    MAIL_FROM_ADDRESS: null,
    MAIL_FROM_NAME: null,
  });

  // show manage stock value
  const [showManageStock, setshowManageStock] = useState(true);

  // paypal
  const [paymentDetails, setpaymentDetails] = useState([]);
  const [paypal_client_id, setpaypal_client_id] = useState(null);

  //nested side bar flag
  const [flag,setFlag] = useState(false);

  // localStorage helper
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };

  // localStorage helper
  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };

  //check if offline helper
  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  };

  //useEffect- to get data on render
  useEffect(() => {
    const fetchData = async () => {
      let result = "YES"
      //await checkInstall();
      if (result === "YES") {
        //call- unauthenticated
        getLanguages();
        getCurrency();
        getSettings();

        //call if authenticated
        if (getCookie() !== undefined ) {
          //getSmtp();
          getPermissionGroups();
          // getEmailSettings();
          // getPaginatedEmails();
        }
      }
    };

    const setManageStock = (res) => {
      if (res.data.length == 0 || res.data[0].value == 1) {
        setshowManageStock(true);
      } else {
        setshowManageStock(false);
      }
    };

    // show manage stock function
    const updateManageStockValue = () => {
      const url = BASE_URL + `/settings/show-manage-stock-menu-info`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setManageStock(res);
          setDataToLocal(url, res);
        })
        .catch((error) => {
          if (offline(url)) {
            let parseRes = getDataFromLocal(url);
            setManageStock(parseRes);
          }
        });
    };

    const getpaypalpaid = () => {
      const url = BASE_URL + `/get-payment-client-id`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data === " ") {
            setpaypal_client_id(null);
          } else {
            setpaypal_client_id(res.data.value);
          }
          setpaymentDetails(res.data);
        });
    };

    fetchData();
   
  }, []);

  // install check
  const checkInstall = async () => {
    const url = BASE_URL + "/check-install";
    if(offline(url)){
      return "YES"
    }else{

      let result = await axios.get(url);
      return result.data;
    }
 
  };

  //Helper
  const setLanguagesList = (res) => {
    setLanguageList(res.data[0]);
    setNavLanguageList(res.data[1]);
    setLanguageListForSearch(res.data[1]);
    setLoading(false);
  };

  //get all languages   Priority ->
  const getLanguages = () => {
    setLoading(true);
    const langUrl = BASE_URL + "/settings/get-lang";
    return axios
      .get(langUrl)
      .then((res) => {
        setLanguagesList(res);
        setDataToLocal(langUrl, res);
      })
      .catch((error) => {
        if (offline(langUrl)) {
          let parseRes = getDataFromLocal(langUrl);
          setLanguagesList(parseRes);
        }
      });
  };

  //Helper
  const setPaginatingLanguages = (res) => {
    setLanguageList(res.data[0]);
    setDataPaginating(false);
  };

  // get paginated languages   Priority ->
  const setPaginatedLanguages = (pageNo) => {
    setDataPaginating(true);
    const langUrl = BASE_URL + "/settings/get-lang?page=" + pageNo;
    return axios
      .get(langUrl)
      .then((res) => {
        setPaginatingLanguages(res);
        setDataToLocal(langUrl, res);
      })
      .catch((error) => {
        if (offline(langUrl)) {
          let parseRes = getDataFromLocal(langUrl);
          setPaginatingLanguages(parseRes);
        }
      });
  };

  //Helper
  // const setSmtpSetting = (res) => {
  //   setSmtp({
  //     ...smtp,
  //     MAIL_MAILER: res.data[0].MAIL_MAILER,
  //     MAIL_HOST: res.data[0].MAIL_HOST,
  //     MAIL_PORT: res.data[0].MAIL_PORT,
  //     MAIL_USERNAME: res.data[0].MAIL_USERNAME,
  //     MAIL_PASSWORD: res.data[0].MAIL_PASSWORD,
  //     MAIL_ENCRYPTION: res.data[0].MAIL_ENCRYPTION,
  //     MAIL_FROM_ADDRESS: res.data[0].MAIL_FROM_ADDRESS,
  //     MAIL_FROM_NAME: res.data[0].MAIL_FROM_NAME,
  //   });
  //   setLoading(false);
  // };

  //get smtp settings   Priority ->
  // const getSmtp = () => {
  //   setLoading(true);
  //   const smtpUrl = BASE_URL + "/settings/get-smtp";
  //   return axios
  //     .get(smtpUrl, {
  //       headers: { Authorization: `Bearer ${getCookie()}` },
  //     })
  //     .then((res) => {
  //       setDataToLocal(smtpUrl, res);
  //       setSmtpSetting(res);
  //     })
  //     .catch((error) => {
  //       if (offline(smtpUrl)) {
  //         let parseRes = getDataFromLocal(smtpUrl);
  //         setSmtpSetting(parseRes);
  //       }
  //     });
  // };

  //Helper
  const setPermissionGroups = (res) => {
    setPermissionGroup(res.data[0]);
    setPermissionGroupForSearch(res.data[1]);
    setPermissions(res.data[2]);
    setLoading(false);
  };

   
  //get permission groups   // Priority online
  const getPermissionGroups = () => {
    setLoading(true);
    const permissionGroupUrl = BASE_URL + "/settings/permission-group-list";
    return axios
      .get(permissionGroupUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPermissionGroups(res);
        setDataToLocal(permissionGroupUrl, res);
      })
      .catch((error) => {
        if (offline(permissionGroupUrl)) {
          let parseRes = getDataFromLocal(permissionGroupUrl);
          setPermissionGroups(parseRes);
        }
      });
  };

  //Helper
  const setPaginatedPermissionGroups = (res) => {
    setPermissionGroup(res.data[0]);
    setPermissionGroupForSearch(res.data[1]);
    setPermissions(res.data[2]);
    setDataPaginating(false);
  };

  // get paginated groups   Priority ->
  const setPaginatedGropus = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/permission-group-list?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedPermissionGroups(res);
        setDataToLocal(url, res);
      })
      .catch((error) => {
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          setPaginatedPermissionGroups(parseRes);
        }
      });
  };

  //Helper
  const setCurrenciesList = (res) => {
    setCurrencyList(res.data[0]);
    setNavCurrencyList(res.data[1]);
    setCurrencyListForSearch(res.data[1]);
    setLoading(false);
  };

  //email settigs
  const setEmailSetting = (res) => {
    setEmailSearch(res.data[1]);
    setDataPaginating(false);
    setLoading(false)
  };

   //get email  // Priority online
   const getEmailSettings = () => {
    setLoading(true);
    const permissionGroupUrl = BASE_URL + "/settings/get-email-template";
    return axios
      .get(permissionGroupUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setEmailSetting(res);
      })
      .catch((error) => {
        setLoading(false)
        // if (offline(permissionGroupUrl)) {
        //   let parseRes = getDataFromLocal(permissionGroupUrl);
        //   setEmailSetting(parseRes);
        // }
      });
  };

  const setPaginatedEmail = (res) => {
    setPageEmail(res.data[0])
    setDataPaginating(false);
  };

  // get paginated groups   Priority ->
  const getPaginatedEmails = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-email-template?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedEmail(res);
      })
      .catch((error) => {
        // if (offline(url)) {
        //   let parseRes = getDataFromLocal(url);
        //   setPaginatedEmail(parseRes);
        // }
      });
  };

  //get all currency  Priority ->
  const getCurrency = () => {
    setLoading(true);
    const currencyUrl = BASE_URL + "/settings/get-currency";
    return axios
      .get(currencyUrl)
      .then((res) => {
        setCurrenciesList(res);
        setDataToLocal(currencyUrl, res);
      })
      .catch((error) => {
        if (offline(currencyUrl)) {
          let parseRes = getDataFromLocal(currencyUrl);
          setCurrenciesList(parseRes);
        }
      });
  };

  //Helper
  const setPaginatingCurrenciesList = (res) => {
    setCurrencyList(res.data[0]);
    setDataPaginating(false);
  };

  // get paginated currency  Priority ->
  const setPaginatedCurrencies = (pageNo) => {
    setDataPaginating(true);
    const currencyUrl = BASE_URL + "/settings/get-currency?page=" + pageNo;
    return axios
      .get(currencyUrl)
      .then((res) => {
        setPaginatingCurrenciesList(res);
        setDataToLocal(currencyUrl, res);
      })
      .catch((error) => {
        if (offline(currencyUrl)) {
          let parseRes = getDataFromLocal(currencyUrl);
          setPaginatingCurrenciesList(parseRes);
        }
      });
  };

  //Helper
  const setGeneralSetting = (res) => {
    setGeneralSettings(res.data);
    const favicon = document.getElementById("favicon");
    favicon.href = BASE_URL + getSystemSettings(res.data, "favicon");
    setLoading(false);
  };

  //get all languages    Priority ->
  const getSettings = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/general-settings";
    return axios
      .get(url)
      .then((res) => {
        setGeneralSetting(res);
        setDataToLocal(url, res);
      })
      .catch((error) => {
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          setGeneralSetting(parseRes);
        }
      });
  };

  return (
    <SettingsContext.Provider
      value={{
        //common
        loading,
        setLoading,

        //general settings
        generalSettings,
        setGeneralSettings,
        getLanguages,
        getCurrency,
        getSettings,

        //currencies
        currencyList,
        setCurrencyList,
        setPaginatedCurrencies,
        navCurrencyList,
        setNavCurrencyList,
        currencyListForSearch,
        setCurrencyListForSearch,

        //languages
        languageList,
        setLanguageList,
        setPaginatedLanguages,
        navLanguageList,
        setNavLanguageList,
        languageListForSearch,
        setLanguageListForSearch,

        //smtp
        // smtp,
        // getSmtp,
        // setSmtp,

        //permission group
        getPermissionGroups,
        permissionGroup,
        setPermissionGroup,
        setPaginatedGropus,
        permissionGroupForSearch,
        setPermissionGroupForSearch,

        //email
        getEmailSettings,
        getPaginatedEmails,
        emailSearch,
        setEmailSearch,
        pageEmail,
        setPageEmail,

        //permissions
        permissions,
        setPermissions,

        //pagination
        dataPaginating,
        setDataPaginating,

        //show  manage stock
        showManageStock,
        setshowManageStock,

        // paypal_paypal_client_id
        paypal_client_id,
        setpaypal_client_id,

        paymentDetails,
        // setpaymentDetails

        //sidebar flag
        flag,
        setFlag
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsContext, SettingsProvider };
