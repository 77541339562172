import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../../../../../contexts/User";
import { RestaurantContext } from "../../../../../../contexts/Restaurant";
import { useParams } from "react-router";

export const useFilteredFloorWaiters = (orderDetails) => {
  const {
    //waiter
    waiterForSearch,
  } = useContext(UserContext);
  const {
    floorForSearch,
    //pagination
  } = useContext(RestaurantContext);
  const { order_id } = useParams();
  const [waiters, setWaiters] = useState({
    searchedWaiter: null,
    waiterList: [],
  });
  const [waiterSelected, setWaiterSelected] = useState(() => orderDetails?.waiter);
  const [tablefloor, setTableFloor] = useState();
  const [getTableFloor,setGetTableFloor] = useState([]);

  
  // get floor api
  useEffect(()=>{
    let getFloor = floorForSearch && floorForSearch.filter((floorSearch)=>{
      return floorSearch.status === "1"
    });

    setGetTableFloor(getFloor);
    getFloor && setTableFloor(getFloor[0]);

  if(order_id){
    if( orderDetails.table && orderDetails.table?.length > 0){
      let floor = getFloor && getFloor.find((floor)=>{
        return floor.id === orderDetails.table[0].floor_id
      });
      setTableFloor(floor)
    }
  }

  },[floorForSearch, orderDetails?.branch]);

  useEffect(() => {
    if (orderDetails?.branch) {
      let tempWaiters =
        waiterForSearch !== null &&
        waiterForSearch.filter((eachWaiter) => {
          return parseInt(eachWaiter.branch_id) === orderDetails?.branch.id;
        });
      setWaiters({
        searchedWaiter: null,
        waiterList: tempWaiters,
      });
    }
  }, [waiterForSearch, orderDetails.branch]);

  useEffect(() => {
    setWaiterSelected(orderDetails?.waiter);
  }, [orderDetails?.waiter]);

  return {waiters, setWaiters, waiterSelected, setWaiterSelected, getTableFloor, tablefloor, setTableFloor};

};
