import React, { useEffect, useRef, useState, useContext } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { ReactComponent as Triangle } from "../../../../public/caret-up.svg";
//functions
import {
    _t,
    customStyle,
  } from "../../../../../../functions/Functions";
import Moment from "react-moment";


const TableList = ({ data, heading, date, size, handleReq = null, url=null, loading=false }) => {

  const headingsToCheck = [
    "total production by item",
    "total production by discard",
    "total stock in hand",
    "brand wise sales",
    "top 5 categories",
    "top 5 branches wise performance",
    "forecasting"
  ];

  const headingsToStyle = [
    "top 5 categories",
    "top 5 branches wise performance",
    "forecasting"
  ];

  const [loadAll,setLoadAll] = useState(false);
  const [dataBody,setDataBody] = useState([]);
  const [dataHead,setDataHead] = useState([]);
  const [tableLoading,setTableLoading] = useState(false);
  const componentRef = useRef();
  
  const getReport= (e)=>{
    componentRef.current.handleDownload();
  }

  const handleLoad = () =>{
    setDataHead(data[0] ? Object.keys(data[0]) : [])
    if(loadAll === true){
      setTableLoading(true);
      setDataBody(data);
      setTimeout(() => {    
        setTableLoading(false);
      }, 300);
    }else{
      setTableLoading(true);
      setDataBody(data.slice(0, 5));
      setTimeout(() => {    
        setTableLoading(false);
      }, 300);
    }
  }
 
  useEffect(()=>{
    const loadData = headingsToCheck.includes(heading?.toLowerCase());
    if(data){
      if(heading.toLowerCase() === "forecasting"){
        data.sort((a, b) => parseFloat(a["Qty Available Today"])-parseFloat(b["Qty Available Today"]));
      }
      if(loadData){
        handleLoad()
      }
    }
 },[loadAll,data])

  return (
    <div className={`col mt-3 px-2 mt-1 position-relative ${size}`}>
     <div className="dashboard__card_list">
      <ReactHTMLTableToExcel
      id="table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table={heading}
      filename={heading}
      sheet="sheet 1"
      buttonText="Sales Detail"
      ref={componentRef}
      />
      <div className="row mb-2 pr-2">
        <div className="list-heading font-weight-bold col-6">
          <h5 className="mt-1">{heading}</h5>
          {
            heading.toLowerCase() === "forecasting" && (
              <p className="table-text" style={{marginTop:"-0.8rem", lineHeight:"1.1rem"}}>Forecast Daily Food Consumption With Precision. Plan Inventory And Reduce Waste Efficiently.</p>
            )
          }
        </div>
        <div className="col-6 d-flex align-items-center justify-content-end p-0">
       
        {
          data && data?.length > 5 &&
          <button className="btn btn-primary btn-sm table-text rounded px-2 mr-2"
          disabled={tableLoading}
          onClick={()=> setLoadAll(!loadAll)}
          >
          {
          loadAll ? "Load less" : "Load All"
          }
          
          </button>
        }
        {
          data && data?.length > 0 && (
            <button
            className="btn btn-primary text-uppercase btn-sm table-text px-2 rounded"
            onClick={(e) => getReport(e)}
            >
            <i class="fa fa-table mr-1" aria-hidden="true"></i>
              download 
            </button>
          )
        }
        </div>
      </div>
      {data && dataHead?.length > 0 ? (
          <div className="col-12">
            <div key={dataBody?.length} className="production-table-view" data-simplebar style={{height: headingsToStyle.includes(heading?.toLowerCase()) ? "14.5rem" : "15.5rem"}}>
              <table id={heading}  className="table mb-0 table-borderless akash-table-for-print-padding">
                {
                  date.startDate && date.endDate && (
                    <tr className="d-none"> 
                      <th colSpan={dataHead?.length}>
                        <h3 className="text-center mt-0">
                          {heading}
                        </h3>
                        <p className="text-center">
                          FROM&nbsp;
                          <Moment format="LL">
                            {date.startDate}
                          </Moment>
                          &nbsp;TO&nbsp;
                          <Moment format="LL">{date.endDate}</Moment>
                        </p>
                      </th>
                    </tr>
                  )
                }
                {
                  data && data?.length > 0 && (
                    <>
                      <thead className={!headingsToStyle.includes(heading?.toLowerCase()) ? "production_table_head" : ""}>
                        <tr>
                          {
                          dataHead?.length && dataHead.map((item)=>{
                            return(
                              <th
                              scope="col"
                              className="list-head-text text-capitalize dashboard_border border-1 p-2 table_child_token"
                              >
                                  {item}
                                  {
                                    item === "Total" &&
                                    <Triangle className = "dashboard_table_sort cursor-pointer" onClick={()=>{
                                      const sorting = [...dataBody].sort((a,b)=>{
                                        return parseFloat(a.Total) - parseFloat(b.Total);
                                      });
                                      setTimeout(() => {
                                        setDataBody(sorting);
                                      }, 200);
                                    }}/>
                                  }
                              </th>
                            )
                          })
                          }
                          
                        </tr>
                      </thead>
                      <tbody>
                      {dataBody?.length && dataBody?.map((dataChild) => {
                        return(
                          <tr>
                           {dataHead.map((data1,i) => {
                            const table = parseFloat((dataChild[data1] * 1).toFixed(2));
                            
                            return (
                                <td
                                  scope="col"
                                  className={`list-text text-capitalize dashboard_border border-1 p-2 `}
                                >
                                  {isNaN(table) ? dataChild[data1] : table}
                                </td>
                              
                              );
                            })}
                          </tr>
                        )
                       })}
                      </tbody>
                    </>
                  )
                }
              </table>
            </div>
          </div>
        ):(
          <div className="btn_load btn_load_height">
           {
            loading ? (
              <>
              {/*loading */}
              <div className={`${(loading) && "loadingBlur"}`}></div>
              <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </>
            ) : (
              <button className="btn btn-sm btn-primary rounded-md" onClick={()=> url && handleReq(url)}>Load Data</button>
            )}
          </div>
      )}
     </div> 
    </div>
  );
};

export default TableList;
