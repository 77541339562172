import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
const DealReport = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let { loading, setLoading, dataPaginating, setDataPaginating, generalSettings } = useContext(SettingsContext);
    const { authUserInfo } = useContext(UserContext);
    let { branchForSearch } = useContext(RestaurantContext);
    let { foodForPOS } = useContext(FoodContext);    
  
    //all data
    const [reportData, setReportData] = useState(null);
    const [reportSummaryData, setReportSummaryData] = useState(null);
    const [itemForSearch, setItemForSearch] = useState([]);
    const componentRef = useRef();
    const componentRef1 = useRef();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [branch, setBranch] = useState(null);
    const [item, setItem] = useState(null); 
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");

    const [branchSearch, setBranchSearch] = useState(null);
    useEffect(()=>{
        const filterItems = foodForPOS && foodForPOS.filter((item)=> item.is_deal === 1);
        setItemForSearch(filterItems)
    },[foodForPOS]);
  
    useEffect(() => {
        setSiteName(getSystemSettings(generalSettings, "siteName"));
        if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
          setBranchSearch(
            branchForSearch.filter(
              (branch) => branch.id === authUserInfo.details.branch_id
            )
          );
        } else {
          setBranchSearch(branchForSearch);
        }
       
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }, [generalSettings]);

      const handleBranch=(branch)=>{
        setBranch(branch)
      }

      const handleItem=(item)=>{
        setItem(item)
      }
  
    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
      setGeneratedReport(false)   
      if (        
        startDate !== null &&
        endDate !== null 
      ) {
        setDataPaginating(true);
        let fromDate = moment(startDate).format("YYYY-MM-DD");
        let toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-deal-detail-report";
        let formData = new FormData();
        formData.append("from_date", fromDate);
        formData.append("to_date", toDate);
        formData.append("branch_id", branch?.id ? branch?.id : "");
        formData.append("item_id", item ? item?.id : "");
  
        return axios
          .post(url,formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setGeneratedReport(true);
            setReportData(res.data[0])
            setDataPaginating(false);
            componentRef.current.handleDownload();
          
          })
          .catch((error) => {
            setDataPaginating(false);
          });
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date, to date and branch"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };

     //get summary reports filter
     const getBranchSummary = () => {
        setReportSummaryData(null);
        setGeneratedReport(false)   
        if (        
          startDate !== null &&
          endDate !== null
        ) {
          setDataPaginating(true);
          let fromDate = moment(startDate).format("YYYY-MM-DD");
          let toDate = moment(endDate).format("YYYY-MM-DD");
          const url = BASE_URL + "/settings/get-deal-summary-report";
          let formData = new FormData();
          formData.append("from_date", fromDate);
          formData.append("to_date", toDate);
          formData.append("branch_id", branch?.id ? branch?.id : "");
          formData.append("item_id", item ? item?.id : "");
    
          return axios
            .post(url,formData, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setGeneratedReport(true);
              setReportSummaryData(res.data[0])
              setDataPaginating(false);
              componentRef1.current.handleDownload();
            
            })
            .catch((error) => {
              setDataPaginating(false);
            });
        } else {
          toast.error(
            `${_t(
              t(
                "Please select from date, to date and branch"
              )
            )}`,
            {
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
        }
      };
  

  
    return (
      <>
        <Helmet>
          <title>{_t(t("Deal's Report"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Deal's Detail Report"
          sheet="sheet 1"
          buttonText="Deal Detail"
          ref={componentRef}
        />   
        
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="summary-to-xls"
          filename="Deal's Summary Report"
          sheet="sheet 1"
          buttonText="Deal Summary"
          ref={componentRef1}
        />  
  
         {/* Excel Report*/}
         <div className="d-none">
         <div>           
           <div className="fk-print">
             <div className="container">
               <div className="row">
                 <div className="col-12">
                   {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                         <table
                            className="table table-bordered table-hover min-table-height mt-3"
                            id="table-to-xls"
                         >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                            <tr style={{ border: "0px" }}>
                                <th colspan="8" style={{ border: "0px" }}>
                                <div className="row">
                                    <div className="col-12">
                                    <h3 className="text-center mb-0">
                                        {siteName}
                                    </h3>
                                    <h3 className="text-center mt-0">
                                        Deal's Detail Report
                                    </h3>
                                    <p className="text-center">
                                        FROM&nbsp;
                                        <Moment format="LL">
                                        {startDate}
                                        </Moment>
                                        &nbsp;TO&nbsp;
                                        <Moment format="LL">{endDate}</Moment>
                                    </p>
                                    </div>
                                </div>
                                </th>
                            </tr>
                            <tr>
                              <th
                                  scope="col"
                              >
                              {_t(t("Branch"))}
                              </th>
                              <th
                                scope="col"
                                >
                                {_t(t("Deal's Name"))}
                               </th>
                                <th
                                scope="col"
                                >
                                {_t(t("S/L"))}
                                </th>
                                <th
                                scope="col"
                                >
                                {_t(t("Order Date"))}
                                </th>
                                <th
                                scope="col"
                                >
                                {_t(t("Order Id"))}
                                </th>
                                <th
                                scope="col"
                                >
                                {_t(t("Food Group"))}
                                </th>
                                <th
                                scope="col"
                                >
                                {_t(t("Item Name"))}
                                </th>
                                <th
                                scope="col"
                                >
                                {_t(t("Qty"))}
                                </th>
                                                                
                             </tr>
                            </thead>
                            <tbody className="align-middle">                                
                            {
                                Object.keys(reportData).map(
                                (group_name, index) => {
                                    return(
                                    <div key={index}>
                                
                                <tr>
                                    <th
                                    scope="row"
                                    >
                                    {group_name}
                                    </th>
                                </tr>
                                {
                                  Object.keys(reportData[group_name]).map((deal, index) => {
                                    let totalQty = 0; 
                                    let s_No = 0;
                                    
                                    return(
                                     <div key={index}>
                                      <tr>
                                        <td></td>
                                        <th>{deal}</th>
                                      </tr>
                                      
                                      {reportData[group_name][deal].map((item, i) => {
                                         if(item.food_item !== deal){
                                          totalQty += parseFloat(item.quantity); 
                                          s_No += 1                                 
                                          return (
                                          <tr>
                                            <td></td>
                                            <td></td>
                                              <td
                                              scope="row"
                                              >
                                              {s_No}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                               <Moment format="LL">
                                                 {item.order_date}  
                                               </Moment>                                      
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                              {item.order_id}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                              {item.food_group}
                                              </td> 
                                              <td
                                              scope="row"
                                              >
                                              {item.food_item}
                                              </td> 
                                              <td
                                              scope="row"
                                              >
                                              {item.quantity}
                                              </td>                                 
                                                                                                                                                              
                                          </tr>
                                          );
                                         }
                                      })}
                                      <tr>                                  
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <th
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      >
                                      Total
                                      </th>                                  
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        <strong>
                                         {formatPrice(totalQty)}
                                        </strong>
                                      </td>                                                               
                                      </tr>
                                     </div> 
                                    ) 
                                  })
                                }
                             </div>
                            );
                            }
                            )
                            }                                
                            </tbody>

                            <tfoot style={{ border: "0px" }}>
                            <tr style={{ border: "0px" }}>
                                <td
                                colspan="7"
                                className="text-center"
                                style={{ border: "0px" }}
                                >
                                <h5 className="mt-3">
                                    Powered by indolj.pk
                                </h5>
                                </td>
                            </tr>
                            </tfoot>
                         </table>
                        </div>
                        </div>
                     )}
                 </div>
               </div>
             </div>
           </div>
         </div>
         </div>
         
         {/* Excel Report*/}
         <div className="d-none">
         <div>           
           <div className="fk-print">
             <div className="container">
               <div className="row">
                 <div className="col-12">
                   {reportSummaryData !== null &&
                    reportSummaryData !== undefined &&
                    generatedReport === true && (
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                         <table
                            className="table table-bordered table-hover min-table-height mt-3"
                            id="summary-to-xls"
                        >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                              <tr style={{ border: "0px" }}>
                                  <th colspan="6" style={{ border: "0px" }}>
                                  <div className="row">
                                      <div className="col-12">
                                      <h3 className="text-center mb-0">
                                          {siteName}
                                      </h3>
                                      <h3 className="text-center mt-0">
                                          Deal's Summary Report
                                      </h3>
                                      <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                          {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                      </p>
                                      </div>
                                  </div>
                                  </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                >
                                {_t(t("Branch"))}
                                </th>
                                <th
                                  scope="col"
                                >
                                {_t(t("Deal's Name"))}
                                </th>
                                <th
                                scope="col"
                                >
                                {_t(t("S/L"))}
                                </th>
                                <th
                                scope="col"
                                >
                                {_t(t("Food Group"))}
                                </th>
                                
                                <th
                                scope="col"
                                >
                                {_t(t("Item Name"))}
                                </th>
                                <th
                                scope="col"
                                >
                                {_t(t("Qty"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">                                
                              {
                                  Object.keys(reportSummaryData).map(
                                  (group_name, index) => {
                                      return(
                                    <div key={index}>
                                  
                                      <tr>
                                          <th
                                          scope="row"
                                          >
                                          {group_name}
                                          </th>
                                      </tr>
                                  {
                                    Object.keys(reportSummaryData[group_name]).map((deal,index)=>{
                                      let totalQty = 0;
                                      let dealQty = 0;
                                      let itemS_no = 0;
                                    
                                      return(
                                    <div key={index}>
                                      <tr>
                                        <th></th>
                                          <th
                                          scope="row"
                                          >
                                          {deal}
                                          </th>
                                        
                                      </tr>
                                    
                                      {reportSummaryData[group_name][deal].map((item, i) => {
                                        if(deal !== item.food_item){
                                            totalQty+= parseFloat(item.quantity);
                                            itemS_no += 1;
                                            return (
                                            <tr key={i}>
                                              <td></td>
                                              <td></td>
                                                <td
                                                scope="row"
                                                >
                                                {itemS_no}
                                                </td>
                                                <td
                                                scope="row"
                                                >
                                                {item.food_group}                                        
                                                </td>
                                                <td
                                                scope="row"
                                                >
                                                  {item.food_item}
                                                </td>
                                                <td
                                                scope="row"
                                                >
                                                  {item.quantity}
                                                </td>
                                                                                                                                                                
                                            </tr>
                                            );
                                          }else{
                                            dealQty += parseFloat(item.quantity);
                                          }                               
                                      })}
                                      <tr>
                                        <td></td>
                                        <td><strong>{dealQty}</strong></td>
                                        <td></td>
                                        <td></td>
                                        <th
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                        >
                                        Total
                                        </th>                                  
                                        <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                        >
                                          <strong>
                                          {formatPrice(totalQty)}
                                          </strong>
                                        </td>
                                      </tr>
                                      <tr></tr>
                                    </div>
                                    )
                                  })
                                }
                              
                                </div>
                                );
                                }
                              )
                              }                                
                            </tbody>

                            <tfoot style={{ border: "0px" }}>
                            <tr style={{ border: "0px" }}>
                                <td
                                colspan="5"
                                className="text-center"
                                style={{ border: "0px" }}
                                >
                                <h5 className="mt-3">
                                    Powered by indolj.pk
                                </h5>
                                </td>
                            </tr>
                            </tfoot>
                         </table>
                        </div>
                        </div>
                     )}
                 </div>
               </div>
             </div>
           </div>
         </div>
         </div>

        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                     
                      {/* spin loading ends */}
  
                      {/* Loading effect {loading === true ? (
                        tableLoading()
                      ) : ( */}
                      
                        <div key="smtp-form">
                          <div className="gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="row t-mb-15 mb-md-0" >
                              <ul className="t-list fk-breadcrumb col-12">
                                <li className="fk-breadcrumb__list">
                                  <span className="pl-3 t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Deal's Report"))}
                                  </span>
                                </li>
                              </ul>
                              
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-2 d-md-block">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control t-mb-15 mb-md-0"
                                placeholderText={_t(t("From date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>

                            <div className="col-md-2 d-md-block">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control t-mb-15 mb-md-0"
                                placeholderText={_t(t("To date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>                                                                                 
  
                            <div className="col-3">
                              <Select
                                options={branchSearch && branchSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={branch}
                                isClearable
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleBranch}                                
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select a branch")) + ".."}
                              />
                            </div>

                            <div className="col-1">
                            {
                              /*
                              <Select
                                options={itemForSearch && itemForSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={item}
                                isClearable
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleItem}                                
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select deals")) + ".."}
                              />
                              */
                            }
                            </div> 

                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected()}
                              >
                               DETAIL REPORT
                              </button>
                            </div>  
                            
                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                                className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                                onClick={(e) => getBranchSummary()}
                              > 
                                SUMMARY REPORT
                              </button>
                            </div> 
                          </div>                   
                          
                        </div>                                          
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
  
      </>
    );
}

export default DealReport;