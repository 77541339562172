import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";



//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  customStyle,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import Select from "react-select";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const payemntType = [
    {
        name:"Debit",
    },

    {
        name:"Credit",
    },

]

const MDIntegrationAction = () => {
    let {
        //item
        loading,
        setLoading,
        //md integration
        MdIntegrationForSearch,
        getMdIntegrationGroup,
      } = useContext(RestaurantContext);
    const { t } = useTranslation(); 

    const {id} = useParams();
    const history = useHistory();

    let [newIntegration, setNewIntegration] = useState({
            id: null,
            name: "",
            account_no: "",
            type: payemntType[0],
            edit: false
        });
    
    useEffect(() => {
        if(id){
            getMdIntegrationGroup()
        }
    },[id]);    

    useEffect(()=>{
        if(id && MdIntegrationForSearch){
            handleSetEdit()
        }
    },[id, MdIntegrationForSearch]);

    //set edit true & values
    const handleSetEdit = () => {
        let integrationItem = MdIntegrationForSearch.find((item) => item.id === parseInt(id));

        setNewIntegration({
            ...newIntegration,
            id: integrationItem.id,
            name: integrationItem.account_name,
            account_no: integrationItem.account_no, 
            type: {name: integrationItem.type}, 
            edit: true,
        });
    };

     //set name, account
    const handleSetNewIntegration = (e) => {
        setNewIntegration({ ...newIntegration, [e.target.name]: e.target.value });
    };

    const handleSetPayment = (payment) =>{
        setNewIntegration({ ...newIntegration, type: payment})
    }

    //Save New customer
    const handleSaveNewMdIntegration = (e) => {
        e.preventDefault();

        if (newIntegration.name && newIntegration.account_no && newIntegration.type) {
            const url = "/settings/md-accounts-storeUpdate";
            const integrateUrl = BASE_URL + url;
            let formData = new FormData();
            if(id){
                formData.append("id", newIntegration.id);
            }
            formData.append("account_name", newIntegration.name);
            formData.append("account_no", newIntegration.account_no);
            formData.append("type", newIntegration.type.name);
            setLoading(true)
            return axios
            .post(integrateUrl, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setNewIntegration({
                    ...newIntegration,
                    id: null,
                    name: "",
                    account_no: "", 
                    edit: false,
                });
                setLoading(false);
                if(id){
                    toast.success(`${_t(t("Integration update successfully"))}`,
                    {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                }else{
                    toast.success(`${_t(t("Integration saved successfully"))}`,
                    {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                }
                history.push("/dashboard/manage/restaurant/md-integration")
            })
            .catch((error) => {
                setLoading(false)
                toast.error(`${_t(t("please try again"))}`,
                {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
        } else {
        toast.error(`${_t(t("Please select a required field"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
        }
    };

  return (
    <>
        <main id="main" data-simplebar>
            <div className="container-fluid">
                <div className="row t-mt-10 gx-2">
                    {/* left Sidebar */}
                    <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                      <ManageSidebar />
                    </div>
                    {/* left Sidebar ends */}

                    {/* Rightbar contents */}
                    <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                        <div className="t-bg-white">
                            <div className="fk-scroll--pos-menu" data-simplebar>
                                <div className="t-pl-15 t-pr-15">
                                    {/* Loading effect */}
                                    {loading === true ? (
                                    tableLoading()
                                    ) : (
                                    <div key="fragment3">
                                        {/* next page data spin loading */}
                                        {/* <div className={`${dataPaginating && "loading"}`}></div> */}
                                        {/* spin loading ends */}
                
                                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                            <ul className="t-list fk-breadcrumb">
                                            <li className="fk-breadcrumb__list">
                                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                                {!newIntegration.edit
                                                ? _t(t("Add new MD Account"))
                                                : _t(t("Update MD Account"))}
                                                </span>
                                            </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-lg-7">
                                            <div className="row gx-3 align-items-center">                                  
                                            </div>
                                        </div>
                                        </div>
                                        {/* form start here */}
                                        <div key="fragment-branch-1">
                                        <form
                                            onSubmit={ handleSaveNewMdIntegration }
                                            className="row card p-2 mx-3 mb-5 new-text-style"
                                            autoComplete="off"
                                        >
                                            <div className="row">
                                                <div className="form-group col-6 mt-3">
                                                    <label htmlFor="name" className="form-label">
                                                        {_t(t("Name"))}{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="name"
                                                        name="name"
                                                        placeholder="e.g. account name"
                                                        value={newIntegration.name || ""}
                                                        required
                                                        onChange={handleSetNewIntegration}
                                                    />
                                                </div>                            
                                                <div className="form-group mt-3 col-6">
                                                    <label htmlFor="account_no" className="form-label">
                                                        {_t(t("Account No "))}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        id="account_no"
                                                        name="account_no"
                                                        placeholder="e.g. account_no"
                                                        value={newIntegration.account_no || ""}
                                                        required
                                                        onChange={handleSetNewIntegration}
                                                    />
                                                </div>
                                                {/* <div className="form-group mt-3 col-4">
                                                    <label htmlFor="account_no" className="form-label">
                                                        {_t(t("Paymen Type "))}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        options={payemntType !== null ? payemntType : []}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.name}
                                                        classNamePrefix="select"
                                                        onChange={(e)=>handleSetPayment(e)}
                                                        value={newIntegration.type}
                                                        styles={customStyle}
                                                        isMenuOpen={true}
                                                        placeholder={
                                                        _t(t("Please select type")) + ".."
                                                        }                                                
                                                    />
                                                </div> */}
                                            </div>
                            
                                            <div className="row mt-4">
                                            <div className="d-flex justify-content-end">
                                                <div className="col-3 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="btn rounded hov-btn mr-3"
                                                        onClick={()=>(history.push("/dashboard/manage/restaurant/md-integration"))}
                                                    >
                                                        <i className="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                                    </button>
                                                
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary rounded"
                                                    >
                                                        <i className="fa fa-floppy-o" aria-hidden="true"></i> {!newIntegration.edit ? _t(t("Save")) : _t(t("Update"))}
                                                    </button>
                                                </div>
                                            
                                            </div>
                                            </div>  
                                        </form>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Rightbar contents end*/}
                </div>
            </div>
        </main>
    </>
  )
}

export default MDIntegrationAction
