import React, {useState, useEffect} from 'react'
import { ProgressBar } from "react-bootstrap"; 

const MenuEngTable = ({ data, heading, size, handleReq = null, url=null, loading=false }) => {
  
    const [dataBody,setDataBody] = useState([]);
  
    const handleLoad = () =>{
      setDataBody(data)
    }

    useEffect(()=>{
    if(data){
      handleLoad()
    }
    },[data])

  return (
    <div className={`col mt-3 px-2 mt-1 position-relative ${size}`}>
     <div className="dashboard__card_list">
      <div className="row mb-2 pr-2">
        <div className="list-heading font-weight-bold col-6">
          <h5 className="mt-1">{heading}</h5>
        </div>
        <div className="col-6 d-flex align-items-center justify-content-end p-0">
       
        </div>
      </div>
      {data && dataBody?.length > 0 ? (
          <div className="col-12">
            <div key={dataBody?.length} className="production-table-view" data-simplebar style={{height:"12.5rem"}}>
              <table id={heading}  className="table mb-0 table-borderless akash-table-for-print-padding">
                <tbody>
                  {dataBody?.length && dataBody?.map((item) => {
                    return(
                      <tr className='table-text text-capitalize dashboard_border border-1 p-3'>
                        <td className='p-2'>{item.order_item_name}</td>
                        <td className='p-2'>GP</td>
                        <td className='p-2'>{item.gp_percentage} %</td>
                        <td className='p-2' style={{minWidth:"60px"}}>
                          <div className="progress" style={{height:"0.6rem"}} role="progressbar" aria-label="Danger example" aria-valuemin="0" aria-valuemax="100">
                            <div className={`progress-bar progress-bar-striped progress-bar-animated ${heading === "Top 5 Low Profit Items" ? "bg-red" : "bg-primary"}`} style={{width:`${item.gp_percentage}%`}}></div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ):(
          <div className="btn_load btn_table_height">
           {
            loading ? (
              <>
                {/*loading */}
                <div className={`${(loading) && "loadingBlur"}`}></div>
                <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </>
            ) : (
              <button className="btn btn-sm btn-primary rounded-md" onClick={()=> url && handleReq(url, heading === "Top 5 Low Profit Items" ? "asc" : "desc")}>Load Data</button>
            )}
          </div>
      )}
     </div> 
    </div>
  )
}

export default MenuEngTable
