import React, { useEffect, useContext, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  modalLoading,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
  getSystemSettings,
  // vatPercent,
  showPropertyPrice,
  checkPermission,
  useCustomHooks,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";

//importing context consumer here
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import { UserContext } from "../../../../contexts/User";

const deliveryTime = ["15", "30", "45", "60", "75", "90", "120"]

const OnlineOrders = () => {
  //getting context values here
  const {
    //common
    loading,
    setLoading,
    generalSettings,
  } = useContext(SettingsContext);

  const { getDeliveryUser, deliveryForSearch, authUserInfo } =
    useContext(UserContext);
  const {orderTypeList, workPeriodListForBranch, branchForSearch, orderCounter} = useContext(RestaurantContext);
  const toastObj = {
    backgroundColor:"#0a8d6e",
    autoClose: 10000,
    style: {
      fontSize: "16.5px", // Set the font size in the style object
    },
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

  const {
    //common
    onlineOrdersAdmin,
    setOnlineOrdersAdmin,
    onlineOrdersAdminForSearch,
    getOnlineOrders,
    setPaginatedOnlineOrders,
    //pagination
    dataPaginating,
    setDataPaginating,
    propertyGroupForSearch
  } = useContext(FoodContext);

  const { t } = useTranslation();
  //print bills
  const componentRef = useRef();
  const component2Ref = useRef();
  let customerPrint =useRef();
  const con_Kot = useRef("");
  const docNo = useRef("");
  const { renderNetBill, totalPriceWithTax, onlineOrders } = useCustomHooks();

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    cancel: false,
    time_to_deliver: null,
    delivery_man_id: null,
    uploading: false,
    printIp: true,
  });
  const [taxType, setTaxType] = useState("");
  const [activebtn , setActivebtn] = useState("");

  // kot print
  const [kotPrintIdx, setKotPrintIdx] = useState(0);
  const [selectedKot, setSelectedKot] = useState([]);
  const [foodGroupsToPrint, setFoodGroupsToPrint] = useState(null);
  //kot
  // const [selectedKot, setSelectedKot] = useState([]);
  // const [kotPrintIdx, setKotPrintIdx] = useState(0);
  // const [foodGroupsToPrint, setFoodGroupsToPrint] = useState(null);
  
  //search result
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });
  const [btnDisable,setBtnDisable] = useState(false);

  const [availableDeliveryTypes, setAvailableDeliveryTypes] = useState([]);

  //change branch state
  const [changeBranch,setChangeBranch] = useState({
    branch: null,
    id: null,
  });

  //change status state
  const [changeStatus,setChangeStatus] = useState({
    status:null,
    id:null,
    remarks:""
  });

  //status state
  const [orderStatus, setOrderStatus] = useState(null);

  const customStyle = {
    control: (provided) => ({
      ...provided,
      minHeight: "2rem",
      marginTop: "0",
      marginBottom: "0",
    }),
    placeholder:(provided) => ({
      ...provided,
      marginTop: "0",
      padding: "0 0.4rem",
    }),
    singleValue: (provided) => ({
      ...provided,
      alignSelf: "center",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
    }),
    valueContainer: (provided) => ({
      ...provided,
      marginTop: "0",
      padding: "0 0.4rem",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0 0.4rem",
      svg: {
        height: "2rem",
      },
    }),
  };
 
  function bracketReplace(id) {
    let property_group_id = id.replace(/[\[\]']+/g, "");
    return property_group_id;
  }

  useEffect(()=>{
    const filteredDeliveryTypes = orderTypeList && orderTypeList.data.filter(
      (order) => order.status === 'On' && (order.name?.toLowerCase() === "takeaway" || order.name?.toLowerCase() === "delivery")
    );
    if (filteredDeliveryTypes.length > 0) {
      setAvailableDeliveryTypes(filteredDeliveryTypes);
    }
  },[orderTypeList]);

    const handleOrderSubmitSuccessful = () =>{
      setCheckOrderDetails({
        ...checkOrderDetails,
        item: null,
        time_to_deliver: null,
        reason_of_cancel: null,
        delivery_man_id: null,
        settle: false,
        printIp: true
      });
      setKotPrintIdx(0);
      setSelectedKot({});
      setFoodGroupsToPrint({});
      setBtnDisable(false);
      docNo.current = "";
      document.getElementById('close-btn').click();
    }


    const handleKotItem = () => {
      const kotObj = { ...selectedKot };
     
      let orderItems = [...checkOrderDetails.item.orderedItems];
      orderItems.forEach((obj) => {
        const kotKey = obj.kot
          ? obj.kot === "No"
            ? obj.kot
            : obj.kot_ip
          : "No";
        const groupKey = obj.food_group ? obj.food_group : "None";
        const consolidatedKot = checkOrderDetails.item.theBranch.consolidated_kot == 1;
        const branchKot = checkOrderDetails.item.theBranch.consolidated_ip;
        // branch wise consolidated kot
      if(consolidatedKot){
        if(
          kotObj.hasOwnProperty(branchKot) &&
          kotObj[branchKot] &&
          Object.keys(kotObj[branchKot])?.length
        ){
          if (
            kotObj[branchKot].hasOwnProperty(groupKey) &&
            kotObj[branchKot][groupKey] &&
            kotObj[branchKot][groupKey]?.length
          ){
            kotObj[branchKot] = {
              ...kotObj[branchKot],
              [groupKey]: [...kotObj[branchKot][groupKey], { ...obj }],
            };
          }else{
            kotObj[branchKot] = {
              ...kotObj[branchKot],
              [groupKey]: [{ ...obj }],
           }
          }          
         }else{
            kotObj[branchKot] = {
              // ...kotObj[branchKot],
              [groupKey]: [{ ...obj }],
            };
         }
      }
        
      if(obj.is_special == "1" && obj?.property_item){
        obj.property_item.forEach((prop)=>{
          const kotProp = prop?.kot_ip
          ? prop.kot_ip : "No";
          if (
            kotObj[kotProp] &&
            kotObj.hasOwnProperty(kotProp) &&
            Object.keys(kotObj[kotProp])?.length
          ) {
            if (
              kotObj[kotProp].hasOwnProperty(groupKey) &&
              kotObj[kotProp][groupKey] &&
              kotObj[kotProp][groupKey]?.length
            ){
              kotObj[kotProp] = {
                ...kotObj[kotProp],
                [groupKey]: [...kotObj[kotProp][groupKey], { ...obj , property: {...prop} }],
              };
            
            }else{
              kotObj[kotProp] = {
                ...kotObj[kotProp],
                [groupKey]: [{ ...obj , property: {...prop}  }],
              };
            }
          } else {
            kotObj[kotProp] = {
              [groupKey]: [{ ...obj , property: {...prop} }],
            };
          }
        });
        }else{

            // item wise kot
          if (
            kotObj.hasOwnProperty(kotKey) &&
            kotObj[kotKey] &&
            Object.keys(kotObj[kotKey])?.length
          ) {
            if (
              kotObj[kotKey].hasOwnProperty(groupKey) &&
              kotObj[kotKey][groupKey] &&
              kotObj[kotKey][groupKey]?.length
            ){
              kotObj[kotKey] = {
                ...kotObj[kotKey],
                [groupKey]: [...kotObj[kotKey][groupKey], { ...obj }],
              };
            
            }else{
              kotObj[kotKey] = {
                ...kotObj[kotKey],
                [groupKey]: [{ ...obj }],
              };
            }
          } else {
            kotObj[kotKey] = {
              [groupKey]: [{ ...obj }],
            };
          }
          
        }
      });
          
      setSelectedKot(kotObj);
      return kotObj;
    }

    //All Receipt Control from this handle
    const getCurrentSelectedKots = useRef();
    getCurrentSelectedKots.current = selectedKot;
    const getCurrentKotPrintIndex = useRef();
    getCurrentKotPrintIndex.current = kotPrintIdx;
     /* KOT PRINTING FUNCTION */
  const setNextKotPrint = () => {
    let currentKotIdx = getCurrentKotPrintIndex.current;
    let allKots = getCurrentSelectedKots.current;
    setKotPrintIdx((curr) => curr + 1);
    if (currentKotIdx === Object.keys(allKots).length) {
      handleOrderSubmitSuccessful();
    } else {
      const newNewOrder = Object.values(allKots)[currentKotIdx];
      if(Object.keys(allKots)[currentKotIdx] == checkOrderDetails.item.theBranch.consolidated_ip){
        con_Kot.current = "Consolidated Kot"
      }else{
        con_Kot.current = ""
      }
      setFoodGroupsToPrint(newNewOrder);
      handleOnlyPrint2();
    }
  };

  //get status
  const getStatus = () => {
    const url = BASE_URL + "/website/get-all-order-status";    
    return axios
      .get(url,{
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((res) => {        
        setOrderStatus(res.data);               
      })
      .catch((err) => {        
        toast.error(
          `${_t(
            t(
              "Can't get status something went wrong! "
            )
          )}`,toastObj
        );              
      });
  }

  //useEffect == componentDidMount
  useEffect(() => {
    getOnlineOrders();
    getDeliveryUser();
    getStatus();
  }, []);

  useEffect(() => {
    if (!generalSettings || !generalSettings.length) return;
    setTaxType(getSystemSettings(generalSettings, "tax_type"));
  }, [generalSettings]);
  customerPrint.current =checkOrderDetails;

  //show price of each item in print
  const showPriceOfEachOrderItemPrint = (thisItem) => {
    let price = 0;

    // {
    //   /* fahad comment */
    // }
    let tempPropertyPrice = 0;
    console.log(" thisItem.properties", thisItem.properties, thisItem);
    if (thisItem.properties) {
      //let propertyItems = JSON.parse(thisItem.properties);
      //console.log(" Json Properties", propertyItems);
      // propertyItems.forEach((propertyItem, thisIndex) => {
      //   let temp =
      //     propertyItem.quantity *
      //     propertyItem.price_per_qty *
      //     thisItem.quantity;
      //   tempPropertyPrice = tempPropertyPrice + temp;
      // });

      JSON.parse(thisItem.properties).map((propertyItem, thisIndex) => {
        if (thisIndex !== JSON.parse(thisItem.properties).length) {
          let temp =
            propertyItem.quantity *
            propertyItem.price_per_qty *
            thisItem.quantity;
            tempPropertyPrice = tempPropertyPrice + parseFloat(temp);
        }
      });
    }
    price = thisItem.price - tempPropertyPrice;
    if (taxType === "inclusive") {
      let finalPrice =
        price / (1 + checkOrderDetails.item.theBranch.branch_tax / 100);
      return formatPrice(finalPrice);
    } else {
      return formatPrice(price);
    }
  };

  //search submitted orders here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if(onlineOrdersAdmin){
      if (searchInput.length === 0 || searchInput === "all") {
        setSearchedOrder({ ...searchedOrder, searched: false });
      } else {
        let searchedList = onlineOrdersAdminForSearch && onlineOrdersAdminForSearch?.filter((item) => {
          console.log("items searched  ", item);
          //token
          let lowerCaseItemToken = item.token && JSON.stringify(item.token);

          //customer
          let lowerCaseItemCustomer = item.customer_name && item.customer_name.toLowerCase();
          let customerPhone = item.delivery_phn_no && item.delivery_phn_no;

          //branch
          let lowerCaseItemBranch = item.branch_name && item.branch_name.toLowerCase();

          let lowerCaseItemOrderType = item.trans_type && item.trans_type?.toLowerCase();
          return (
            (lowerCaseItemToken && lowerCaseItemToken.includes(searchInput)) ||
            (lowerCaseItemCustomer && lowerCaseItemCustomer.includes(searchInput)) ||
            (lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput)) ||
            (lowerCaseItemOrderType && lowerCaseItemOrderType.includes(searchInput)) || 
            (customerPhone && customerPhone.includes(searchInput))
          );
        });
        setSearchedOrder({
          ...searchedOrder,
          list: searchedList,
          searched: true,
        });
      }
    } 
  };


  // //print here
  // const handleOnlyPrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   onAfterPrint: () => {
  //     if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {     
  //         handleOnlyPrint2();
  //     }
  //   },
  // });

  const handleOrderConfirmation = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h3 className="text-center">{_t(t("Are you sure?"))}</h3>
            <p className="text-center lg-text">{_t(t("You want to take this online order?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleSubmit(e);
                  onClose()
                }}
              >
                {_t(t("Yes"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleCheckStock = (e) =>{
    e.preventDefault();
    const url = BASE_URL + "/website/checking-stocks";
    let formData = {
      id: checkOrderDetails.item.id,
      branch_id: checkOrderDetails.item.branch_id,
    };
    setLoading(true);
    return axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((res) => {
       if(res.data === ""){
         handleSubmit(e);
       }else{
        handleOrderConfirmation(e);
        toast.error(<p style={{whiteSpace:"pre-line"}}>{res.data}</p>,toastObj);
        document.getElementById('close-btn').click();
       }
       setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const handleSetOrderDetails = (e) => {
    if (!checkOrderDetails.item.trans_type.match(/delivery/i)) {
      parseInt(getSystemSettings(generalSettings, "online_order_stock_check")) === 1 ? 
      handleCheckStock(e) :
      handleSubmit(e);
    }else{
      setCheckOrderDetails({
        ...checkOrderDetails,
        settle: true,
        time_to_deliver: checkOrderDetails.item.theBranch.delivery_time,
        printIp: true
      });
    }
  };

  //for customer print
  const handleCustomerPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () =>{
      handleOrderSubmitSuccessful()
    }
  });  

  //for kithcen
  const handleKitchenPrint = useReactToPrint({
    content: () => component2Ref.current,
  });

  const handleOnlyPrint2 = useReactToPrint({
    content: () => component2Ref.current,
    onAfterPrint: () => {
      setNextKotPrint();
    },
  });

  const handlePrint = () => {
    switch (getSystemSettings(generalSettings, "print_pos_setting")) {
      case "Customer":
        handleCustomerPrint();
        break;

      case "Kitchen":
        setNextKotPrint();;
        break;

      default:
        handlePrintAll();
        break;
    }
  }

    //order list print here
    const handleOnlyPrint = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint: () => {
        if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {     
          handleKitchenPrint();
        }
      },
    });
  
    const handlePrintAll = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint: () => {
        if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
          let kots = getCurrentSelectedKots.current;
          if (kots && Object.keys(kots)?.length) {
            setNextKotPrint();
          } else {
            handleOrderSubmitSuccessful();
          }
        } else {
          handleOrderSubmitSuccessful();
        }
      },
   
    })

  //ready here
  const handleReadyOrder = (id) => {
    let tempSettledOrders = onlineOrdersAdmin;
    let tempSearchedItems = searchedOrder.list;
    const url = BASE_URL + "/settings/settle-order-ready/" + id;
    let theItems = onlineOrdersAdmin.data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          is_ready: 1,
          orderedItems: item.orderedItems.map((eachItem) => {
            return {
              ...eachItem,
              is_cooking: 1,
              is_ready: 1,
            };
          }),
        };
      } else {
        return item;
      }
    });
    if (searchedOrder.list !== null && searchedOrder.list.length > 0) {
      let searchedItems = searchedOrder.list.map((searchedItem) => {
        if (searchedItem.id === id) {
          return {
            ...searchedItem,
            is_ready: 1,
            orderedItems: searchedItem.orderedItems.map((eachorderItem) => {
              return {
                ...eachorderItem,
                is_cooking: 1,
                is_ready: 1,
              };
            }),
          };
        } else {
          return searchedItem;
        }
      });
      setSearchedOrder({
        ...searchedOrder,
        list: searchedItems,
      });
    }
    setOnlineOrdersAdmin({ ...onlineOrdersAdmin, data: theItems });
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {})
      .catch((error) => {
        setOnlineOrdersAdmin(tempSettledOrders);
        setSearchedOrder({
          ...searchedOrder,
          list: tempSearchedItems,
        });
      });
  };

  const handleLocalPrint = () => {
    let formData = formDataReturn();
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post("http://localhost:8081/example-app/api/test", formData, axiosConfig)
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        document.getElementById('close-btn').click();
        handleOrderSubmitSuccessful();
      })
      .catch((err) => {
        handlePrint();
        document.getElementById('close-btn').click();
      });
  };

  const onlinePayment = (item) =>{
    if(item.request_from === "POS" && item.payment_method === "Card"){
      return " Card On Delivery"
    }else if(item.payment_method === "Card" || item.payment_method === "Online"){
      return " Online Paid"
    }else if(item.payment_method === "Cash"){
      return " Cash On Delivery"
    }else{
      return " Swipe Card at your Door Step"
    }
  }
  
  // form data for direct print to printer
  const formDataReturn = () => {
    const workPeriod = workPeriodListForBranch &&
    workPeriodListForBranch.find((tempWorkPeriod) => {
      return (checkOrderDetails.item.branch_id === parseInt(tempWorkPeriod.branch_id)) && tempWorkPeriod.ended_at === null;
    });
    //cash card tax wise total 
    const [cardAmount, cardPercent] = totalPriceWithTax(checkOrderDetails.item.total_payable,checkOrderDetails.item.order_bill, checkOrderDetails.item.vat,checkOrderDetails.item.discount_amount, checkOrderDetails.item,"card");
    const [cashAmount, cashPercent] = totalPriceWithTax(checkOrderDetails.item.total_payable,checkOrderDetails.item.order_bill, checkOrderDetails.item.vat,checkOrderDetails.item.discount_amount, checkOrderDetails.item,"cash");
 
    let formatTime = moment(checkOrderDetails.item.created_at).format('DD-MMM-YYYY hh:mm A');
    const ref_no = checkOrderDetails.item.party_name +  " - ref no :"  + checkOrderDetails.item?.ref_no
       return {
        site_name: getSystemSettings(generalSettings, "siteName"),
        branch: checkOrderDetails.item.theBranch,
        type_print_heading: getSystemSettings(
          generalSettings,
          "type_print_heading"
        ),
        payment_type: onlinePayment(checkOrderDetails.item),
        orderItems: !Object.keys(selectedKot).length === 0 ? selectedKot : handleKotItem(),
        serviceCharge: checkOrderDetails.item.delivery_charge,
        service_amount: 0,
        view_cash_card: parseInt(getSystemSettings(generalSettings,"view_cash_card")),
        cash: cashAmount,
        card: cardAmount,
        cash_percent: cashPercent,
        card_percent: cardPercent,
        discount: checkOrderDetails.item.discount_percentage,
        order_type: checkOrderDetails.item.trans_type,
        subTotal: checkOrderDetails.item.order_bill,
        totalPayable: checkOrderDetails.item.total_payable,
        theVat: checkOrderDetails.item.vat,
        tax_percent: checkOrderDetails.item.tax_percent,
        print_tax : checkOrderDetails.item.theBranch?.print_tax,
        RefNo: ref_no,
        print_footer: getSystemSettings(generalSettings, "type_print_footer"),
        print_bill_footer: getSystemSettings(generalSettings, "print_bill_footer"),
        user_name: authUserInfo && authUserInfo.details && authUserInfo.details.name,
        tax_type: taxType,
        created_by : checkOrderDetails.item?.user_name,
        rider_name: checkOrderDetails.item?.delivery_boy_name ? checkOrderDetails.item.delivery_boy_name : "",
        rider_notes: checkOrderDetails.item?.note_to_rider ? checkOrderDetails.item.note_to_rider : "",
        order_tip: checkOrderDetails.item?.order_tip,
        //customer print
        customerIp: checkOrderDetails.item.theBranch.kitchen_printer_ip != null && JSON.parse(checkOrderDetails.item.theBranch.kitchen_printer_ip)?.[authUserInfo.details.id] ? JSON.parse(checkOrderDetails.item.theBranch.kitchen_printer_ip)[authUserInfo.details.id] : null,
        deliveryIp: checkOrderDetails.item.theBranch.customer_counter_ip != null && checkOrderDetails.item.theBranch.customer_counter_ip !== "" ? checkOrderDetails.item.theBranch.customer_counter_ip : null,
        customerOrder: checkOrderDetails.item.orderedItems,
        party_name: checkOrderDetails.item.trans_type,
        dept_tag: {
          id: checkOrderDetails.item.dept_tag_id,
          name: checkOrderDetails.item.dept_tag_name,
        },
        orderTypeDetails: {
          orderTypeId: checkOrderDetails.item.order_type_id,
          orderTypeName: checkOrderDetails.item.order_type_name,
        },
        partyDetails: {
          partyId: checkOrderDetails.item.party_id,
          partyName: checkOrderDetails.item.party_name,
        },
        is_online: 1,
        guest: 0,
        is_reservation: false,
        isSettle: 0,
        table: null,
        waiter: null,
        payment_amount: "",
        card_number: "",
        newCustomer: 0,
        newCustomerInfo: {
          name: checkOrderDetails.item.customer_name,
          email:"",
          address: checkOrderDetails.item.delivery_address,
          ref_no: checkOrderDetails.item.ref_no,
          phn_no: checkOrderDetails.item.delivery_phn_no,
          nearest_landmark: checkOrderDetails.item.nearest_landmark,
          alt_phn_no: checkOrderDetails.item?.alt_phn_no,
        },
        token: {
          id: parseInt(getSystemSettings(generalSettings, "order_no")) === 1 ? docNo.current : checkOrderDetails.item.token,
          time: formatTime
        },
        total_guest: 1,
        table_id: null,
        table_name: null,
        discount_voucher: "",
        scheme: {
          id: null,
          discount: checkOrderDetails.item.discount_percentage,
          how_much_discount: checkOrderDetails.item.discounted_amount,
        },
        paidMoney: 0,
        dept_commission: "",
        workPeriod: workPeriod ? workPeriod : {
          id: checkOrderDetails.item.work_period_id,
          token: checkOrderDetails.item.token
        },
        order_id: null,
        orderFoodGroups: null,
        selectedPropertyGroup: [],
        propertyGroupForSearch: propertyGroupForSearch,
        return_amount: 0,
        fbr_num: "",
        srb_num: "",
     
      };
    };

  //submit accept order request
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setBtnDisable(true);
    const url = BASE_URL + "/website/accept-order";
    let formData = {
      time_to_deliver: checkOrderDetails.time_to_deliver,
      delivery_man_id: checkOrderDetails.delivery_man_id,
      id: checkOrderDetails.item.id,
    };
    
    handleKotItem()
    
    return axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((res) => {
        if(res.data === "Order Already Accepted" || res.data === "Order In Processing Please Wait"){
          toast.error(res.data);
          setTimeout(() => {     
            getOnlineOrders();
            setSelectedKot({});
            setKotPrintIdx(0);
            setFoodGroupsToPrint({});
            setBtnDisable(false);
          }, 500);
        }else{
          docNo.current = res.data.document_no;
          // let temp = checkOrderDetails.item;
          // temp.is_accepted = 1;
          // temp.time_to_deliver = checkOrderDetails.time_to_deliver;
          // temp.document_no = res.data;
          setCheckOrderDetails({
            ...checkOrderDetails,
            item: res.data,
            time_to_deliver: null,
            reason_of_cancel: null,
            delivery_man_id: null,
            settle: false,
            printIp: true,
          });
          if(orderCounter.online > 0 && orderCounter.online < 5){
            onlineOrders();
          }
          setTimeout(() => {
            if(getSystemSettings(generalSettings, "print_callcenter_kot") === "1" && checkOrderDetails.item?.request_from === "POS"){
              handleCustomerPrint();
            }else{
              const directPrint = parseInt(getSystemSettings(generalSettings, "direct_ip_print")) === 1;
              directPrint ? handleLocalPrint() : handlePrint();
            }
          }, 500);
        }
        setLoading(false);
      })
      .catch((err) => {
        setBtnDisable(false);
        setLoading(false);
        setKotPrintIdx(0);
        setSelectedKot({});
        setFoodGroupsToPrint({});
      });
  };

  //submit cancel order request
  const handleSubmitCancel = (e) => {
    e.preventDefault();
    setLoading(true);
    setBtnDisable(true);
    const url = BASE_URL + "/website/cancel-order";
    let formData = {
      reason_of_cancel: checkOrderDetails.reason_of_cancel,
      id: checkOrderDetails.item.id,
    };
    return axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((res) => {
        if(res.data === "Order Already Cancelled"){
           toast.error("Order Already Cancelled");
           setTimeout(() => {        
            getOnlineOrders();
           }, 500);
        }else{
          let temp = checkOrderDetails.item;
          temp.is_cancelled = 1;
          temp.reason_of_cancel = checkOrderDetails.reason_of_cancel;
          if(orderCounter.online > 0 && orderCounter.online < 5){
            onlineOrders();
          }
          setCheckOrderDetails({
            ...checkOrderDetails,
            item: temp,
            reason_of_cancel: null,
            cancel: false,
            printIp: true,
          });
        }

        setLoading(false);
        setBtnDisable(false);
      })
      .catch((err) => {
        setLoading(false);
        setBtnDisable(false);
      });
  };

  const handlePrintBtn = (item) =>{
    setCheckOrderDetails({
      ...checkOrderDetails,
      item: item,
      settle: false,
      printIp: true
    });
    
    setTimeout(() => {
      let customer = customerPrint.current;
      if(getSystemSettings(generalSettings, "print_callcenter_kot") === "1" && customer.item?.request_from === "POS"){
        handleCustomerPrint()
      }else{
        handleOnlyPrint();
      }
    }, 1000);
  }


  //item list status list 
  const itemListStatus = (item) =>{
    return(
      <>
        <div className="d-flex align-items-center justify-content-center mb-1">
        {parseInt(item.is_cancelled) === 0 ? (
            [
              parseInt(
                item.is_accepted
              ) === 0 ? (
                <span
                  className="btn btn-transparent btn-warning xsm-text text-capitalize"
                  onClick={() => {
                    setCheckOrderDetails({
                      ...checkOrderDetails,
                      item: item,
                      settle: false,
                      cancel: false,
                      printIp: true,
                    });
                  }}
                  data-toggle="modal"
                  data-target="#orderDetails"
                >
                  {_t(t("Accept"))}
                </span>
              ) : (
                <span
                  className={`btn btn-transparent ${item.status.toLowerCase() === "ready to dispatch" ? "btn-primary" : "btn-success"} ${item.status.toLowerCase() === "completed" ? "pe-none" : ""} xsm-text text-capitalize`}
                  onClick={() => changeStatusSave(item)}
                >
                  {item.status}
                </span>
              ),
            ]
          ) : (
            <span
              className="btn btn-transparent btn-light-danger xsm-text text-capitalize px-3"
              onClick={() => {
                setCheckOrderDetails({
                  ...checkOrderDetails,
                  item: item,
                  settle: false,
                  cancel: false,
                  printIp: true,
                });
              }}
              data-toggle="modal"
              data-target="#orderDetails"
            >
              {_t(t("Cancelled"))}
            </span>
          )}        
        </div>
        <div>
          {
            parseInt(item.is_cancelled) === 0 &&  parseInt(item.is_accepted) === 0 && (
            <span
                className="btn btn-transparent btn-danger xsm-text text-capitalize"
                onClick={() => {
                  setCheckOrderDetails({
                    ...checkOrderDetails,
                    item: item,
                    settle: false,
                    cancel: true,
                    printIp: true,
                  });
                }}
                data-toggle="modal"
                data-target="#orderDetails"
              >
                {_t(t("Cancel"))}
            </span>
          )}
        </div>
      </>
    )
  }
  const itemListContent = (item,index) =>{
    return(
        <tr
        className="align-middle"
        key={index}
      >
        <th
          scope="row"
          className="table-text text-capitalize align-middle text-center"
        >
          {index +
            1 +
            (onlineOrdersAdmin.current_page -
              1) *
              onlineOrdersAdmin.per_page}
        </th>

        <td className="table-text text-capitalize align-middle text-center text-secondary table_child_token">
          <div className="d-flex flex-column align-items-center justify-content-center">
            # {item.token}
            {
              parseInt(item.is_accepted) === 1 && (
                <div className="btn btn-sm rounded xsm-text btn-secondary mt-1"
                  onClick={() => {
                    setCheckOrderDetails({
                      ...checkOrderDetails,
                      item: item,
                      settle: false,
                      cancel: false,
                      printIp: true,
                    });
                  }}
                  data-toggle="modal"
                  data-target="#orderDetails"
                >
                  Accepted
                </div>
              )
            } 
          </div>
        </td>

        <td className="table-text align-middle text-center table_child_ordertype">
        {item.trans_type}
        </td>

        <td className="table-text align-middle text-center table_child_ordertype">
          {item.customer_name}
        </td>

        <td className="table-text align-middle text-center table_child_ordertype">
          {item.delivery_phn_no}
        </td>

        <td className="table-text align-middle text-center table_child_branch">
          {item.branch_name || "-"}
        </td>

        <td className="table-text text-capitalize align-middle text-center">
          {itemListStatus(item)}
        </td>

        <td className="table-text text-capitalize align-middle text-center">
          <div className="d-flex align-items-center justify-content-center">
           <div className="d-flex flex-column "> 
            <span
              className="btn btn-primary xsm-text text-capitalize rounded-sm py-1 mb-1"
              data-toggle="modal"
              data-target="#changeBranch"
              title="Change Branch"
              onClick={() => setChangeBranch({...changeBranch,id: item.id})}
            >
              {_t(t("Ch-Branch"))}
            </span>
            {(parseInt(item.is_cancelled) === 1 || parseInt( item.is_accepted) === 1) && (
            <span
              className="btn btn-secondary xsm-text text-capitalize rounded-sm py-1"
              title="Change Status"
              onClick={()=> setChangeStatus({...changeStatus, id:item.id})}
              data-toggle="modal"
              data-target="#changeStatus"
            >
              {_t(t("Ch-Status"))}
            </span>
          )}
          </div>
          {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Online Order Print") ? 
            <button
            className="btn btn-secondary btn-sm ml-2 rounded-sm"
            onClick={()=>handlePrintBtn(item)}
            style={{height:"30px"}}
          >
            <i className="fa fa-print"></i>
          </button>:null}

          {/* is_delivered */}
          {/* {parseInt(item.is_ready) ===
            0 && (
            <button
              className="btn btn-success btn-sm ml-2"
              title="Served"
              onClick={() => {
                handleReadyOrder(item.id);
              }}
            >
              <i className="fa fa-check"></i>
            </button>
          )} */}
          </div>
        </td>

        <td className="table-text text-capitalize align-middle text-center text-secondary">
          <Moment format="LLL">
            {item.created_at}
          </Moment>
        </td>
        <td className="table-text align-middle text-center table_child_branch">
          {item.ref_no || "-"}
        </td>
        <td className="table-text align-middle text-center table_child_branch">
          {item.payment_method || "-"}
        </td>
        <td className="table-text align-middle text-center table_child_branch">
          {item.user_name || "-"}
        </td>
      </tr>
    )
  }


  //change branch api
  const [loader,setLoader] = useState(false)
  const changeBranchSave = (e) => {
    e.preventDefault();
    if(changeBranch!==null){
      setLoader(true);
      const url = BASE_URL + "/website/change-order-branch";
    let formData = {
      branch_id: changeBranch.branch.id,
      id: changeBranch.id,
      branch_name: changeBranch.branch.name
    };
    return axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((res) => {
        setLoader(false);
        toast.success(
          `${_t(
            t(
              "Branch has been changed."
            )
          )}`,toastObj
        );
        getOnlineOrders();
        document.getElementById("close-btn-branch").click();        
        setChangeBranch({
          branch:null,
          id:null,
        })
      })
      .catch((err) => {
        setLoader(false);
        toast.error(
          `${_t(
            t(
              "Please try again"
            )
          )}`,toastObj
        );              
      });

    }else{
      toast.error(
        `${_t(
          t(
            "Please select branch"
          )
        )}`,toastObj
      );
    }
  }

  const changeStatusSave = (e) => {
    if(!e?.id){
      e.preventDefault();
    }
    const url = BASE_URL + "/website/change-order-status";
    if((changeStatus.status!==null && changeStatus.id !== null) || e?.id){
      if(e?.id){
        setDataPaginating(true)
      }else{
        setLoader(true);
      }
      
    let formData = {};
    if(e?.id){
      formData.id = e.id;
      formData.status = e.status;
      formData.type= "status"
    }else{
      formData.status= changeStatus.status.description;
      formData.id= changeStatus.id;
      formData.remarks= changeStatus.remarks;
      formData.type= "change_status";
    }

    return axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${getCookie()}`,
        },
      })
      .then((res) => {
        if(e?.id){
          setDataPaginating(false)
        }else{
          setLoader(false);
        }
        toast.success(`${_t(
            t("Status has been changed.")
          )}`,toastObj
        );
        getOnlineOrders();
        if(!e?.id){
          document.getElementById("close-btn-status").click();        
          setChangeStatus({
            status:null,
            id:null,
            remarks:""
          })
        }
      })
      .catch((err) => {
        if(e?.id){
          setDataPaginating(false)
        }else{
          setLoader(false);
        }
        toast.error(
          `${_t(
            t(
              "Please try again"
            )
          )}`,toastObj
        );              
      });

    }else{
      toast.error(
        `${_t(
          t(
            "Please fill both fields"
          )
        )}`,toastObj
      );
    }
  }

  //update staus confirmation
  const handleChangeStatus =(item)=>{
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h3 className="text-center">{_t(t("Are you sure?"))}</h3>
            <p className="text-center lg-text">{_t(t("You want to take change status of online order?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  changeStatusSave(item);
                  onClose()
                }}
              >
                {_t(t("Yes"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });

  }

  // console.log("check details==>",checkOrderDetails?.item?.id,changeBranch);

  return (
    <>
      <Helmet>
        <title>{_t(t("Online / Call Center Orders"))}</title>
      </Helmet>

      {/* Print bill */}
      <div className="d-none">
        <div ref={componentRef}>
          {checkOrderDetails && checkOrderDetails.item && (
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                  {
                    parseInt(getSystemSettings(generalSettings, "print_logo")) === 1 && (
                      <div className="mx-auto" style={{ width: "120px" }}>
                        <img
                          src={`${getSystemSettings(
                            generalSettings,
                            "type_logo"
                          )}`}
                          style={{ width: "100%"}}
                          //height={100}
                        />
                      </div>
                    ) 
                  }
                  {
                    parseInt(getSystemSettings(generalSettings, "print_siteName")) === 1 && (
                      <div className="fk-print-text font-weight-bold text-center sm-text mt-2">
                        {getSystemSettings(generalSettings, "siteName")}
                      </div>
                    ) 
                   } 
                   {
                    parseInt(getSystemSettings(generalSettings, "print_branchName")) === 1 && (
                      <div className="fk-print-text font-weight-bold text-uppercase text-center sm-text mt-2">
                        {checkOrderDetails.item.branch_name}
                      </div>
                    ) 
                   }
                   {
                    parseInt(getSystemSettings(generalSettings, "print_branchAddress")) === 1 && (
                      <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                      {checkOrderDetails.item.theBranch !== null &&
                      checkOrderDetails.item.theBranch.address
                        ? checkOrderDetails.item.theBranch.address
                        : ""}
                      </p>
                    )
                   }
                   {
                    parseInt(getSystemSettings(generalSettings, "print_branchPhone")) === 1 &&(
                      <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                      {_t(t("call"))}:{" "}
                      {checkOrderDetails.item.theBranch !== null &&
                      checkOrderDetails.item.theBranch.phn_no
                        ? checkOrderDetails.item.theBranch.phn_no
                        : ""}
                     </p>
                    )
                  }
                  {
                    parseInt(getSystemSettings(generalSettings, "print_heading")) === 1 && (
                      <p className="mb-0 sm-text fk-print-text text-center">
                       {getSystemSettings(generalSettings, "type_print_heading")}
                      </p>
                    )
                  }
                  
                   {/* <span className="d-block fk-print-text text-uppercase text-center lg-text myBorderTopCustomer pt-1">
                     {_t(t(getSystemSettings(generalSettings, "token_text")))} {" - "} {checkOrderDetails.item.token}
                    </span>*/}
                    <div className = "d-flex align-items-center justify-content-between myBorderTopCustomer"> 
                      <span className="fk-print-text text-uppercase text-center print-text pt-1 mr-1">
                       {_t(t(getSystemSettings(generalSettings, "token_text")))} {" - "} {checkOrderDetails.item && checkOrderDetails.item.token}
                      </span>

                      {
                        parseInt(getSystemSettings(generalSettings, "order_no")) === 1 && (
                          <span className="fk-print-text text-uppercase text-right print-text pt-1 ml-1">
                            Invoice No - {" "}
                            {checkOrderDetails.item?.document_no && checkOrderDetails.item.document_no}
                          </span>
                        )
                      }
                    </div>

                    {
                      checkOrderDetails.item.order_type_name && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                        {checkOrderDetails.item.order_type_name +
                          " - " +
                          checkOrderDetails.item?.party_name}
                        </p>
                      )
                    }
                    
                    <p className="mb-0 mt-0 print-text fk-print-text text-capitalize text-center">
                      {_t(t("Online Customer"))}
                    </p>
                      <div className="myBorder mb-2"></div>
                    <p className="mb-1 print-text fk-print-text text-capitalize">
                      {_t(t("Date"))}:{" "}
                      <Moment format="LL">
                        {checkOrderDetails.item.created_at}
                      </Moment>
                      {", "}
                      <Moment format="LT">
                        {checkOrderDetails.item.created_at}
                      </Moment>
                    </p>

                    <p className="mb-1 print-text fk-print-text text-capitalize">
                      Ref No : {checkOrderDetails.item.ref_no}
                    </p>
                    {
                      parseInt(getSystemSettings(generalSettings, "print_paymentType")) === 1 && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                          Payment Type: 
                          {onlinePayment(checkOrderDetails.item)}
                        </p>
                      )
                    }
                    {
                      checkOrderDetails.item.customer_name && (
                        <p className="mb-0 print-text fk-print-text text-capitalize">
                          {_t(t("Customer Name"))}:{" "}
                          {checkOrderDetails.item.customer_name}
                        </p>
                      )
                    }
                    {
                      checkOrderDetails.item.delivery_phn_no && (
                        <p className="mb-0 print-text fk-print-text text-capitalize">
                          {_t(t("Contact"))}:{" "}
                          {checkOrderDetails.item.delivery_phn_no}
                        </p>
                      )
                    }
                    {
                      checkOrderDetails.item?.alt_phn_no && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                          {_t(t("Alt Phone #"))}:{" "}
                          {checkOrderDetails.item.alt_phn_no}
                        </p>
                      )
                    }
                    {
                      checkOrderDetails.item.delivery_address && (
                        <p className="mb-0 print-text fk-print-text text-capitalize">
                          {_t(t("Delivery Address"))}:{" "}
                          {checkOrderDetails.item.delivery_address}
                        </p>
                      )
                    }

                    {
                      checkOrderDetails.item?.nearest_landmark && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                          {_t(t("Nearest Landmark"))}:{" "}
                          {checkOrderDetails.item.nearest_landmark}
                        </p>
                      )
                    }

                    {
                      checkOrderDetails.item.delivery_boy_name && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                          {_t(t("Rider name "))}:{" "}
                          {checkOrderDetails.item.delivery_boy_name}
                        </p>
                      )
                    }

                    <table className="w-100 mb-0 table-borderless">
                      <thead>
                        <tr>
                          <th
                          scope="col"
                          className="fk-print-text print-text text-capitalize "
                          >
                          {/* <div className="d-flex flex-wrap align-items-center">
                            <span style={{width:"10.5%"}} className="d-inline-block">
                            {_t(t("qty"))}
                            </span>
                            <span style={{width:"82%"}} className="d-inline-block">
                            {_t(t("item"))}
                            </span>
                           </div>*/}
                           {_t(t("qty"))}  {_t(t("item"))}
                          </th>
                          <th
                          scope="col"
                          className="fk-print-text print-text text-capitalize text-right"
                          >      
                          {_t(t("T"))}.{_t(t("price"))} 
                          </th>                        
                        </tr>
                      </thead>
                      <tbody>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.orderedItems.map(
                            (thisItem, indexThisItem) => {
                              return (
                                <>
                                <tr>
                                  <td className="fk-print-text print-text text-capitalize">
                                   <div className="d-flex flex-wrap align-items-center">
                                      <span style={{width:"9%"}} className="fk-print-text d-inline-block print-text">
                                          {thisItem.quantity}{" "}
                                      </span>
                                       <span style={{width:"83%"}} className="fk-print-text d-inline-block ml-2">
                                        {thisItem.food_item}
                                        {thisItem.variation !== null &&
                                          "(" + thisItem.variation + ")"}
                                       </span>
                                      </div> 
                                    </td>
                                    <td className="fk-print-text print-text text-capitalize text-right">   
                                       {showPriceOfEachOrderItemPrint(thisItem)}
                                    </td> 
                                 </tr>     

                                    {thisItem.properties !== "" && 
                                        JSON.parse(thisItem.properties).map(
                                          (propertyItem, thisIndex) => {
                                            if (
                                              thisIndex !==
                                              JSON.parse(thisItem.properties)
                                                .length -
                                                1
                                            ) {
                                              return (
                                               <tr>
                                                <td className="fk-print-text print-text text-capitalize">
                                                
                                                  <span className="fk-print-text text-capitalize print-text d-inline-block mr-1" style={{marginLeft:"13%"}}>
                                                    -{thisItem.quantity}
                                                    {propertyItem.quantity > 1
                                                      ? "*" +
                                                        propertyItem.quantity
                                                      : ""}{" "}
                                                    {propertyItem.property}
                                                  </span>
                                                </td>
                                                  <td className="fk-print-text print-text text-capitalize text-right">
                                                    {showPropertyPrice(
                                                      thisItem.quantity,
                                                      propertyItem.quantity,
                                                      propertyItem.price_per_qty,
                                                      taxType,
                                                      checkOrderDetails.item.theBranch.branch_tax
                                                    )}
                                                  </td>
                                               </tr>
                                              );
                                            } else {
                                              return (
                                              <tr>
                                               <td className="fk-print-text print-text text-capitalize">
                                                
                                                  <span className="fk-print-text text-capitalize print-text d-inline-block mr-1" style={{marginLeft:"13%"}}>
                                                    -{thisItem.quantity}
                                                    {propertyItem.quantity > 1
                                                      ? "*" +
                                                        propertyItem.quantity
                                                      : ""}{" "}
                                                    {propertyItem.property}
                                                  </span>{" "}
                                                </td>
                                                <td className="fk-print-text print-text text-capitalize text-right">
                                                  {showPropertyPrice(
                                                    thisItem.quantity,
                                                    propertyItem.quantity,
                                                    propertyItem.price_per_qty,
                                                    taxType,
                                                    checkOrderDetails.item.theBranch.branch_tax
                                                  )}
                                                </td>
                                              </tr>
                                              );
                                            }
                                          }
                                    )}

                                    {/* properties */}
                                    {/* fahad comment */}
                                    {/* {thisItem.properties !== null && (
                                      <div className="d-block">
                                        {JSON.parse(thisItem.properties).map(
                                          (propertyItem, thisIndex) => {
                                            if (
                                              thisIndex !==
                                              JSON.parse(thisItem.properties)
                                                .length -
                                                1
                                            ) {
                                              return (
                                                <span className="text-capitalize print-text d-inline-block mr-1">
                                                  -{thisItem.quantity}
                                                  {propertyItem.quantity > 1
                                                    ? "*" +
                                                      propertyItem.quantity
                                                    : ""}{" "}
                                                  {propertyItem.property}
                                                </span>
                                              );
                                            } else {

                                            }
                                          }
                                        )}
                                      </div>
                                    )} */}
                                    {/*<div className="d-block print-text">
                                      {showPriceOfEachOrderItemPrint(thisItem)}
                                    </div>
                                    {thisItem.properties !== "" && (
                                      <div className="d-block">
                                        {JSON.parse(thisItem.properties).map(
                                          (propertyItem, thisIndex) => {
                                            if (
                                              thisIndex !==
                                              JSON.parse(thisItem.properties)
                                                .length -
                                                1
                                            ) {
                                              return (
                                                <div
                                                  className="d-block text-capitalize print-text mt-1"
                                                >
                                                  <span>
                                                    {showPropertyPrice(
                                                      thisItem.quantity,
                                                      propertyItem.quantity,
                                                      propertyItem.price_per_qty
                                                    )}
                                                  </span>
                                                </div>
                                              );
                                            } else {
                                              return (
                                                <div
                                                  className="d-block text-capitalize print-text"
                                                >
                                                  <span></span>
                                                </div>
                                              );
                                            }
                                          }
                                        )}
                                      </div>
                                    )}
                                        */}
                                    {/* properties */}
                                    {/* fahad comment */}
                                    {/* {thisItem.properties !== null && (
                                      <div className="d-block">
                                        {JSON.parse(thisItem.properties).map(
                                          (propertyItem, thisIndex) => {
                                            return (
                                              <div
                                                className="d-block"
                                                className={`text-capitalize print-text`}
                                              >
                                                <span>
                                                  {formatPrice(
                                                    thisItem.quantity *
                                                      propertyItem.quantity *
                                                      propertyItem.price_per_qty
                                                  )}
                                                  <br />
                                                </span>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )} */}
                                </>
                              );
                            }
                          )}
                      </tbody>
                    </table>

                    <div className="myBorder mb-1"></div>
                    <table className="w-100 mb-0 table-borderless">
                      <tbody>
                        <tr>
                          <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            {_t(t("total"))}
                          </th>
                          <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {formatPrice(checkOrderDetails.item.order_bill)}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {parseFloat(checkOrderDetails.item.vat) > 0 && (
                      <table className="w-100 mb-0 table-borderless">
                        <tbody>
                          {checkOrderDetails.item.vat_system === "igst" ? (
                            <tr>
                              <th className="py-0 pb-1 fk-print-text print-text">
                                {/* <span className="d-block print-text">IGST</span> */}
                                <span className="d-block print-text">
                                {checkOrderDetails.item.theBranch?.print_tax}
                                {checkOrderDetails.item && `(${checkOrderDetails.item.tax_percent}%)`}
                                </span>
                              </th>
                              <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(checkOrderDetails.item.vat)}
                              </td>
                            </tr>
                          ) : (
                            <>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                  <span className="d-block print-text">CGST</span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(parseFloat(0))}
                                </td>
                              </tr>
                              <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                  <span className="d-block print-text">SGST</span>
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                  {formatPrice(parseFloat(0))}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    )}
                    {parseFloat(checkOrderDetails.item.delivery_charge) > 0 ? (
                      <table className="w-100 mb-0 table-borderless">
                        <tbody>
                          <tr>
                            <th className="py-0 pb-1 fk-print-text print-text">
                              Delivery Charges
                            </th>
                            <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                              {formatPrice(
                                parseFloat(
                                  checkOrderDetails.item.delivery_charge
                                )
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : null}
                    {parseFloat(checkOrderDetails.item.discount_percentage) >
                      0 ||
                    parseFloat(checkOrderDetails.item.discounted_amount) > 0 ? (
                      <table className="w-100 mb-0 table-borderless">
                        <tbody>
                          <tr>
                            <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                {_t(t("discount"))}
                                {checkOrderDetails &&
                                  checkOrderDetails.item.scheme_id !== null &&
                                  `(${parseFloat(
                                    checkOrderDetails.item.discount_percentage
                                  )}%)`}
                            </th>
                            <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                              {formatPrice(
                                checkOrderDetails.item.discounted_amount
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : null}

                    {
                      parseFloat(checkOrderDetails.item?.order_tip) > 0 && (
                        <table className="w-100 mb-0 table-borderless">
                          <tbody>
                            <tr>
                              <th className="fk-print-text print-text text-capitalize">
                                {_t(t("Order Tip"))}
                              </th>
                              <td className="fk-print-text print-text text-capitalize text-right">
                                {formatPrice(checkOrderDetails.item.order_tip)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )
                    }

                    <div className="myBorder"></div>
                    <table className="w-100 mb-0 table-borderless">
                      <tbody>
                        <tr>
                          <th className="fk-print-text print-text text-capitalize">
                            {_t(t("grand total"))}
                          </th>
                          <td className="fk-print-text print-text text-capitalize text-right">
                            {formatPrice(checkOrderDetails.item.total_payable)}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {parseInt(getSystemSettings(generalSettings,"view_cash_card")) === 1 && (
                      <React.Fragment>
                        <div className="myBorder mt-1"></div>
                        {renderNetBill(checkOrderDetails.item.total_payable,checkOrderDetails.item.order_bill, checkOrderDetails.item.vat, checkOrderDetails.item?.discounted_amount, checkOrderDetails.item, "cash", "Cash Net Bill")}
                        
                        {renderNetBill(checkOrderDetails.item.total_payable, checkOrderDetails.item.order_bill, checkOrderDetails.item.vat, checkOrderDetails.item?.discounted_amount, checkOrderDetails.item, "card", "Card Net Bill")}
                     </React.Fragment>  
                     )
                    }

                    <div className="myBorder"></div>
                    {checkOrderDetails.item?.note_to_rider && checkOrderDetails.item?.note_to_rider !== "" && 
                      <p className="mb-1 print-text fk-print-text text-capitalize mx-1">
                      {"Rider Note : " +
                          (checkOrderDetails.item.note_to_rider && 
                            checkOrderDetails.item.note_to_rider !== "" &&
                            checkOrderDetails.item.note_to_rider
                        )}
                      </p>    
                     }
                    <p className="white-space my-0 sm-text fk-print-text text-center text-capitalize">
                      {getSystemSettings(generalSettings, "type_print_footer")}
                    </p>
                    <p className="my-0 sm-text fk-print-text text-capitalize text-center">
                      {_t(t(getSystemSettings(generalSettings, "print_bill_footer")))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Print bill kitchen */}
      <div className="d-none">
        <div ref={component2Ref}>
          {checkOrderDetails && (
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12 mt-3">
                    {/*<span className="d-block mt-1 fk-print-text fk-print-text--bold text-uppercase text-center mt-3 lg-text">
                     {_t(t(getSystemSettings(generalSettings, "token_text")))} {" - "}
                      {checkOrderDetails.item && checkOrderDetails.item.token}
                     </span>*/}

                  <div className = "d-flex align-items-center justify-content-between"> 
                    <span className="fk-print-text text-uppercase text-center print-text pt-1 mr-1">
                    {_t(t(getSystemSettings(generalSettings, "token_text")))} {" - "} {checkOrderDetails.item && checkOrderDetails.item.token}
                    </span>

                    {
                      parseInt(getSystemSettings(generalSettings, "order_no")) === 1 && (
                        <span className="fk-print-text text-uppercase text-right print-text pt-1 ml-1">
                          Invoice No - {" "}
                          {checkOrderDetails.item?.document_no && checkOrderDetails.item.document_no}
                        </span>
                      )
                    }
                  </div>
                    
                    <p className="mb-0 fk-print-text text-capitalize text-center lg-text fk-print-text--bold">
                      {_t(t("Online"))}
                    </p>

                   {con_Kot?.current && con_Kot?.current !== "" &&
                    <p className="mb-0 mt-0 fk-print-text fk-print-text--bold text-capitalize text-center border border-dark">
                      {con_Kot.current}
                    </p>
                    }
                    <p className="mb-0 mt-0 fk-print-text text-capitalize text-center fk-print-text--bold myBorderBottom">
                      {_t(t("kitchen orders"))}
                      { checkOrderDetails.printIp === false &&
                        foodGroupsToPrint &&
                        Object.values(foodGroupsToPrint)?.length &&
                        Object.values(foodGroupsToPrint)[0][0] &&
                        Object.values(foodGroupsToPrint)[0][0]?.kot &&
                        Object.values(foodGroupsToPrint)[0][0].kot !== "No" ? (
                          <span>
                            {" "}
                            (
                            {_t(t(Object.values(foodGroupsToPrint)[0][0].kot))}
                            )
                          </span>
                        ) : (
                          ""
                        )}
                    </p>

                    <table className="w-100 m-0 p-0 table-borderless">
                      <tr className="myBorderPrintBottom">
                      <th
                        className="fk-print-text text-capitalize"
                        >
                         <div className="d-flex flex-wrap">
                            <span className="d-inline-block lg-text fk-print-text--bold fk-print-text print_w-20" >
                            {_t(t("Qty"))}
                            </span>
                      
                            <span className="d-inline-block lg-text fk-print-text--bold fk-print-text print_w-80" >
                            {_t(t("Item"))}
                            </span> 
                          </div>
                          </th>
                        </tr>
                      <tbody>
                      { checkOrderDetails.printIp === false ? (
                        foodGroupsToPrint &&
                        Object.keys(foodGroupsToPrint)?.length &&
                        Object.keys(foodGroupsToPrint).map(
                          (theGrp, grpIndex) => {
                            return (
                              <>
                                <tr>
                                  <td className="text-center">
                                    <div
                                      className={`${
                                        grpIndex === 0
                                          ? "myBorder"
                                          : "myBorderBottom"
                                      } lg-text fk-print-text--bold fk-print-text`}
                                    >
                                      {theGrp}
                                    </div>
                                  </td>
                                </tr>
                            
                                {foodGroupsToPrint[theGrp].map(
                                  (printItem, printItemIndex) => {
                                    return (
                                      <> 
                                      
                                        <tr className="">
                                          <th className="fk-print-text text-capitalize">
                                          { printItem?.is_special != "1" || !printItem.hasOwnProperty('property') ? (
                                            <>
                                            <div className="d-flex flex-wrap align-items-center">
                                              <span className="print_w-20 ml-1 pl-1">
                                               {printItem.quantity}
                                              </span>
                                              <span className="d-inline-block lg-text fk-print-text--bold fk-print-text print_w-80 p-0 m-0">
                                                {printItem?.food_item}{" "}
                                                {
                                                  printItem.variation && 
                                                  printItem.variation !== null && (
                                                    <span className="d-inline-block lg-text fk-print-text--bold fk-print-text mr-1" >
                                                      (
                                                      {
                                                        printItem.variation
                                                      }
                                                      )
                                                    </span>
                                                  )}
                                                  <span className="d-inline-block lg-text fk-print-text--bold fk-print-text pl-1 ml-2" >
                                                    {
                                                      printItem.order_notes && (
                                                        <span className="d-inline-block print_font fk-print-text--bold mt-1 fk-print-text mb-1 ml-3" >
                                                        | Instructions: {printItem.order_notes}
                                                        </span>
                                                      )
                                                    }
                                                  </span>
                                              </span>
                                              
                                            </div>

                                            {
                                              printItem?.item_description && parseInt(getSystemSettings(generalSettings, "order_no")) === 0 &&
                                              <div className="online_desc d-flex flex-wrap align-items-center">
                                                <span className="print_w-20 ml-1">
                                                </span>
                                                <span className="white-space print_w-80 ml-1">
                                                  {printItem.item_description}
                                                </span>
                                              </div>
                                            }
                                           
                                            {/* properties */}
                                            {
                                              JSON.parse(printItem.properties) &&
                                              JSON.parse(printItem.properties).length >
                                                0 ? (
                                                <div className="pl-2 d-flex flex-wrap align-items-center">
                                                  {printItem.property_groups && printItem.property_groups.map(
                                                    (
                                                      propertyGrpName,
                                                    ) => {
                                                        let theGroup =
                                                        propertyGroupForSearch &&
                                                        propertyGroupForSearch.find(
                                                          (theItem) => {
                                                            return propertyGrpName === (theItem?.id) ;
                                                          }
                                                        );
                                                        
                                                        let propertWithGroup = []
                                                        JSON.parse(printItem.properties).forEach(prop => {
                                                          const propertyGroupIds = JSON.parse(prop?.property_group_id);
                                                          if(propertyGroupIds.includes(propertyGrpName)){
                                                            propertWithGroup.push(prop);
                                                          }
                                                        });
                                                       
                                                      return (
                                                        <>
                                                         {
                                                          propertWithGroup && propertWithGroup?.length > 0 && theGroup &&
                                                          <>
                                                           <span className="print_w-20"></span>
                                                           <span className=" d-inline-block mr-1 fk-print-text lg-text fk-print-text--bold print_w-80">
                                                            {theGroup &&
                                                              theGroup.name + " :"}
                                                            
                                                            </span>
                                                          </>
                                                         }
                                                         {propertWithGroup && propertWithGroup?.map(
                                                           (
                                                             propertyName,
                                                             propertyIndex
                                                             ) => {
                                                               if (
                                                                 printItem.properties &&
                                                                 parseInt(
                                                                   bracketReplace(
                                                                     propertyName
                                                                     .property_group_id
                                                                     )
                                                                     ) ===
                                                                     theGroup?.id
                                                                     ) {
                                                                       return(
                                                                         
                                                                   <>
                                                                    <span className="print_w-20"></span>
                                                                    <span className="text-capitalize d-inline-block mr-1 fk-print-text lg-text fk-print-text--bold print_w-80" >
                                                                    <span>
                                                                    ({printItem.quantity >
                                                                        1 ?
                                                                        printItem.quantity * propertyName.quantity
                                                                        : propertyName.quantity
                                                                          }){" "}
                                                                    </span>
                                                                    {
                                                                      propertyName?.property
                                                                    }{" "}
                                                                    {
                                                                      propertyIndex == propertWithGroup?.length - 1
                                                                       ? "." : ","}
                                                                    </span>
                                                                   </>
                                                                  )
                                                                  
                                                                }else {
                                                              return true;
                                                            }
                                                           })}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                  </div>
                                              ) : (
                                                ""
                                              )
                                            }
                                            </>
                                            ) : (
                                              <div>                                              
                                              <div className="d-flex flex-wrap align-items-center">
                                               <span className="print_w-20 ml-1 pl-1">
                                                {printItem.quantity * printItem.property.quantity}
                                                </span>
                                                 <span className="d-inline-block lg-text fk-print-text--bold fk-print-text print_w-80 p-0 m-0">
                                                   {printItem.property.property}{" "}
                                                 
                                                  <span className="d-inline-block lg-text fk-print-text--bold fk-print-text pl-1 ml-2" >
                                                   {printItem.order_notes ? `(${printItem.order_notes})` : ""}
                                                  </span>
                                                 </span>
                                               </div>
                                              </div> 
                                            )}
                                          </th>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                              </>
                            );
                          }
                        )) : (
                          checkOrderDetails.item &&
                          checkOrderDetails.item.orderedItems.map(
                            (thisItem, indexThisItem) => {
                              return (
                                <>
                                <tr>
                                  <td className="fk-print-text print_font text-capitalize">
                                    <div className="d-flex flex-wrap align-items-center">
                                    <span className="print_w-20 ml-1 pl-2 fk-print-text--bold ">
                                    {thisItem.quantity}{" "}
                                    </span>
                                      <span className="d-inline-block print_font fk-print-text--bold print_w-80 mr-1" >
                                        {thisItem.food_item}
                                        {thisItem.variation !== null &&
                                          "(" + thisItem.variation + ")"}
                                          {
                                            thisItem.order_notes && (
                                              <span className="d-inline-block print_font fk-print-text--bold mt-1 fk-print-text mb-1 ml-3" >
                                               | Instructions: {thisItem.order_notes}
                                              </span>
                                            )
                                          }
                                      </span>
                                    </div>
                                    {
                                      thisItem?.item_description &&
                                      <div className="online_desc d-flex flex-wrap align-items-center">
                                        <span className="print_w-20 ml-1">
                                        </span>
                                        <span className="white-space print_w-80 ml-1">
                                          {thisItem.item_description}
                                        </span>
                                      </div>
                                    }
                                    
                                    {thisItem.properties !== "" && (
                                      <div className="d-block">
                                        {JSON.parse(thisItem.properties).map(
                                          (propertyItem, thisIndex) => {
                                            if (
                                              thisIndex !==
                                              JSON.parse(thisItem.properties)
                                                .length -
                                                1
                                            ) {
                                              return (
                                                <div
                                                  className="d-flex flex-wrap text-capitalize align-items-center mb-1"
                                                >
                                                <span className="print_w-20"></span>
                                                  <span className="text-capitalize print_font d-inline-block mr-1 fk-print-text--bold print_w-80" >
                                                   ({thisItem.quantity}
                                                    {propertyItem.quantity > 1
                                                      ? "*" +
                                                        propertyItem.quantity
                                                      : ""}){" "}
                                                    {propertyItem.property}
                                                  </span>
                                                </div>
                                              );
                                            } else {
                                              return (
                                                <div
                                                  className="d-flex flex-wrap align-items-center mb-1"
                                                >
                                                  <span className="print_w-20"></span>
                                                  <span className="text-capitalize print_font d-inline-block mr-1 fk-print-text--bold print_w-80" >
                                                    ({thisItem.quantity}
                                                    {propertyItem.quantity > 1
                                                      ? "*" +
                                                        propertyItem.quantity
                                                      : ""}){" "}
                                                    {propertyItem.property}
                                                  </span>{" "}
                                                </div>
                                              );
                                            }
                                          }
                                        )}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                                </>
                              );
                            }
                          )
                        )}
                       
                      </tbody>
                    </table>
                    <div className="myBorderBottom"></div>
                      <p className="my-1 print-text fk-print-text text-capitalize lg-text fk-print-text--bold">
                        {_t(t("Date"))}: {" "}
                        {checkOrderDetails.item && (
                          <Moment format="LLL">
                            {checkOrderDetails.item.created_at}
                          </Moment>
                          )}
                      </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Settle modal */}
      <div className="modal fade overflow-auto" id="orderDetails" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {/* show order token on modal header */}
                  {_t(t("Order details, Token"))}: #
                  {checkOrderDetails.item && checkOrderDetails.item.token}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* if loading true show loading effect */}
            {loading ? (
              <div className="modal-body">{modalLoading(5)}</div>
            ) : (
              <div className="modal-body">
                {checkOrderDetails.item && (
                  // if this item is not settled then show settle-cancel button
                  <>
                    {checkOrderDetails.item &&
                    parseInt(checkOrderDetails.item.is_cancelled) !== 1 ? (
                      <div className="text-right">
                        {!checkOrderDetails.settle &&
                          !checkOrderDetails.cancel &&
                          authUserInfo?.details?.user_type !== "call_center" &&
                          parseInt(checkOrderDetails.item.is_accepted) !== 1 && (
                            <>
                              <button
                                className="btn btn-primary px-3 rounded-md text-uppercase mr-2"
                                onClick={() => {
                                  setCheckOrderDetails({
                                    ...checkOrderDetails,
                                    cancel: true,
                                    printIp: true,
                                  });
                                }}
                              >
                                {_t(t("Cancel"))}
                              </button>
                            </>
                        )}
                        {!checkOrderDetails.settle &&
                          checkOrderDetails.cancel && (
                            <button
                              className="btn btn-outline-primary px-3 rounded-md text-uppercase mr-2"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  cancel: false,
                                  reason_of_cancel: null,
                                });
                              }}
                            >
                              {_t(t("Back"))}
                            </button>
                          )}
                        {checkOrderDetails.settle ? (
                          ""
                        ) : (
                          <>
                            {console.log("here 3", checkOrderDetails)}
                            {authUserInfo?.details?.user_type !== "call_center" &&
                            parseInt(checkOrderDetails.item.is_accepted) !== 1 ? (
                              <>
                                {!checkOrderDetails.cancel && (
                                  <button
                                    disabled={btnDisable}
                                    className="btn btn-success px-3 rounded-md text-uppercase"
                                    onClick={(e) => handleSetOrderDetails(e)}
                                  >
                                    {_t(t("Accept"))}
                                  </button>
                                )}
                              </>
                            ) : (
                              <div className="text-center bg-success text-white py-2">
                                {_t(t("Order has been accepted"))}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="text-center bg-secondary text-white py-2">
                        {_t(t("This order has been cancelled"))}
                      </div>
                    )}
                  </>
                )}

                {/* show this if order settle is not true, if true show payment input field */}
                {!checkOrderDetails.cancel ? (
                  <>
                    {!checkOrderDetails.settle ? (
                      <div className="col-12 filtr-item">
                        <div className="fk-order-token t-bg-white">
                          <div className="fk-order-token__body">
                            <div className="fk-addons-table">
                              <div className="fk-addons-table__head text-center">
                                {_t(t("order token"))}: #
                                {checkOrderDetails.item &&
                                  checkOrderDetails.item.token}
                              </div>
                              <div className="fk-addons-table__info">
                                <div className="row g-0">
                                  <div className="col-2 text-center border-right">
                                    <span className="fk-addons-table__info-text text-capitalize">
                                      {_t(t("S/L"))}
                                    </span>
                                  </div>
                                  <div className="col-3 text-center border-right">
                                    <span className="fk-addons-table__info-text text-capitalize">
                                      {_t(t("food"))}
                                    </span>
                                  </div>
                                  <div className="col-4 text-left pl-2 border-right">
                                    <span className="fk-addons-table__info-text text-capitalize">
                                      {_t(t("Additional Info"))}
                                    </span>
                                  </div>
                                  <div className="col-2 text-center border-right">
                                    <span className="fk-addons-table__info-text text-capitalize">
                                      {_t(t("QTY"))}
                                    </span>
                                  </div>
                                  <div className="col-1 text-center">
                                    <span className="fk-addons-table__info-text text-capitalize">
                                      {_t(t("Status"))}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {checkOrderDetails.item &&
                                checkOrderDetails.item.orderedItems.map(
                                  (thisItem, indexThisItem) => {
                                    
                                    return (
                                      <div className="fk-addons-table__body-row">
                                        <div className="row g-0">
                                          <div className="col-2 text-center border-right d-flex">
                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                              {indexThisItem + 1}
                                            </span>
                                          </div>
                                          <div className="col-3 text-center border-right d-flex">
                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                              {thisItem.food_item} (
                                              {thisItem.food_group})
                                            </span>
                                          </div>
                                          <div className="col-4 text-center border-right t-pl-10 t-pr-10">
                                            {thisItem.variation !== null && (
                                              <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                <span className="font-weight-bold mr-1">
                                                  {_t(t("variation"))}:
                                                </span>
                                                {thisItem.variation}
                                              </span>
                                            )}

                                            {/* fahad comment */}
                                            {/* {thisItem.properties !== null && (
                                              <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                <span className="font-weight-bold mr-1">
                                                  {_t(t("properties"))}:
                                                </span>
                                                {JSON.parse(
                                                  thisItem.properties
                                                ).map(
                                                  (propertyItem, thisIndex) => {
                                                    if (
                                                      thisIndex !==
                                                      JSON.parse(
                                                        thisItem.properties
                                                      ).length -
                                                        1
                                                    ) {
                                                      return (
                                                        propertyItem.property +
                                                        `${
                                                          propertyItem.quantity >
                                                          1
                                                            ? "(" +
                                                              propertyItem.quantity +
                                                              ")"
                                                            : ""
                                                        }` +
                                                        ", "
                                                      );
                                                    } else {
                                                      return (
                                                        propertyItem.property +
                                                        `${
                                                          propertyItem.quantity >
                                                          1
                                                            ? "(" +
                                                              propertyItem.quantity +
                                                              ")"
                                                            : ""
                                                        }`
                                                      );
                                                    }
                                                  }
                                                )}
                                              </span>
                                            )} */}

                                            {thisItem.properties !== "" && (
                                              <span className="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                <span className="font-weight-bold mr-1">
                                                  {_t(t("properties"))}:
                                                </span>
                                                {JSON.parse(
                                                  thisItem.properties
                                                ).map(
                                                  (propertyItem, thisIndex) => {
                                                    if (
                                                      thisIndex !==
                                                      JSON.parse(
                                                        thisItem.properties
                                                      ).length -
                                                        1
                                                    ) {
                                                      return (
                                                        propertyItem.property +
                                                        `${
                                                          propertyItem.quantity >
                                                          1
                                                            ? "(" +
                                                              propertyItem.quantity +
                                                              ")"
                                                            : ""
                                                        }` +
                                                        ", "
                                                      );
                                                    } else {
                                                      return (
                                                        propertyItem.property +
                                                        `${
                                                          propertyItem.quantity >
                                                          1
                                                            ? "(" +
                                                              propertyItem.quantity +
                                                              ")"
                                                            : ""
                                                        }`
                                                      );
                                                    }
                                                  }
                                                )}
                                              </span>
                                            )}
                                          </div>
                                          <div className="col-2 text-center border-right d-flex">
                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                              {thisItem.quantity}
                                            </span>
                                          </div>

                                          <div className="col-1 text-center d-flex">
                                            <label className="mx-checkbox mx-checkbox--empty m-auto">
                                              <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                                {parseInt(
                                                  thisItem.is_cooking
                                                ) === 1 ? (
                                                  [
                                                    parseInt(
                                                      thisItem.is_ready
                                                    ) === 1 ? (
                                                      <i
                                                        className="fa fa-check text-success"
                                                        title={_t(t("Ready"))}
                                                      ></i>
                                                    ) : (
                                                      <i
                                                        className="fa fa-cutlery text-secondary"
                                                        title={_t(t("Cooking"))}
                                                      ></i>
                                                    ),
                                                  ]
                                                ) : (
                                                  <i
                                                    className="fa fa-times text-primary"
                                                    title={_t(t("Pending"))}
                                                  ></i>
                                                )}
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="my-2 payment-type-parent">
                        <form
                          className="border my-2 alert-success rounded-lg"
                          onSubmit={(e)=>{
                            e.preventDefault()
                            if(!checkOrderDetails.delivery_man_id){
                              toast.error("Please select rider")
                              return;
                            }
                            parseInt(getSystemSettings(generalSettings, "online_order_stock_check")) === 1 ? 
                            handleCheckStock(e)
                           : handleSubmit(e)}}
                          autoComplete="off"
                        >
                          <div className="mt-2 d-flex align-items-center justify-content-end mr-2">
                              <button
                                className="btn btn-outline-primary px-3 rounded-md text-uppercase mr-2"
                                onClick={() => {
                                  setCheckOrderDetails({
                                    ...checkOrderDetails,
                                    settle: false,
                                    time_to_deliver: null,
                                  });
                                }}
                              >
                                {_t(t("Back"))}
                              </button>   
                              <div className="ml-2">
                                <button
                                  disabled={btnDisable}
                                  className="btn btn-success text-center px-3 text-uppercase rounded-sm"
                                  type="submit"
                                >
                                  {_t(t("Accept"))}
                                </button>
                              </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div className="table-text ml-3 py-2 font-weight-md">
                                {_t(t("Time To Deliver The Order"))}
                              </div>
                              <div className="mx-4 mb-1 d-flex align-items-center position-relative w-75">
                                <button type="button" className="btn btn-decrement" onClick={()=>  {
                                  setCheckOrderDetails({
                                      ...checkOrderDetails,
                                      time_to_deliver: checkOrderDetails?.time_to_deliver == 0 ? 0 :parseInt(checkOrderDetails?.time_to_deliver) - 1,
                                    })}}> − </button>
                                <input
                                  type="number"
                                  min="0"
                                  step="1"
                                  name="time_to_deliver"
                                  placeholder="Time in minutes"
                                  className="form-control table-text text-center table-text rounded"
                                  onChange={(e) => {
                                    setCheckOrderDetails({
                                      ...checkOrderDetails,
                                      time_to_deliver: e.target.value,
                                      printIp: true
                                    });
                                  }}
                                  value={checkOrderDetails.time_to_deliver}
                                  required
                                />
                                <button type="button" className="btn btn-primary btn-increment" onClick={()=>  {
                                  setCheckOrderDetails({
                                      ...checkOrderDetails,
                                      time_to_deliver: parseInt(checkOrderDetails?.time_to_deliver) + 1,
                                    })}}>+</button>
                              </div>

                              <div className="d-flex align-items-center mt-2 mx-2 flex-wrap">
                                {
                                  deliveryTime && deliveryTime.map((time) =>{
                                    return (
                                      <div className="p-1 mb-1 text-break">
                                        <button type="button" className={`rounded-sm p-0 btn-custom btn btn-sm ${time === checkOrderDetails?.time_to_deliver ? "btn-success" : "btn-primary"} table-text`} onClick={()=>{
                                          setCheckOrderDetails({
                                            ...checkOrderDetails,
                                            time_to_deliver: time,
                                          })}}
                                        >
                                          {time}
                                        </button>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="table-text ml-2 py-2 font-weight-md">
                                {_t(t("Assign Delivery Man"))}
                              </div>
                              <div className="mx-2 mb-2 pb-2 d-flex justify-content-between align-items-center">
                                <Select
                                  options={deliveryForSearch ? deliveryForSearch : []}
                                  getOptionLabel={(option) => option.name + " (" + option.phn_no + ")"}
                                  getOptionValue={(option) => option.id}
                                  value={checkOrderDetails.delivery_man_id ? deliveryForSearch.find((delivery)=> delivery.id === checkOrderDetails.delivery_man_id) : null}
                                  onChange={(delivery) => {
                                    setCheckOrderDetails({
                                      ...checkOrderDetails,
                                      item:{
                                        ...checkOrderDetails.item,
                                        delivery_boy_name: delivery.name,
                                      },
                                      delivery_man_id: delivery.id,
                                      printIp: true
                                    });
                                  }}
                                  className="table-text flex-grow-1"
                                  maxMenuHeight="200px"
                                  placeholder="Please select deliveryman"
                                  styles={customStyle}
                                />
                              </div>
                            </div>
                          </div>

                        </form>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="my-2 payment-type-parent">
                      <form
                        className="border my-2 alert-danger rounded-lg"
                        onSubmit={handleSubmitCancel}
                        autoComplete="off"
                      >
                        <div className="table-text text-center py-2 font-weight-md">
                          {_t(t("Please write a reason"))}
                        </div>
                        <div className="addons-list__item mx-2 mb-1">
                          <input
                            type="text"
                            name="reason_of_cancel"
                            placeholder="Please write a reason"
                            className="form-control table-text pl-2"
                            onChange={(e) => {
                              setCheckOrderDetails({
                                ...checkOrderDetails,
                                reason_of_cancel: e.target.value,
                                printIp: true
                              });
                            }}
                            value={checkOrderDetails.reason_of_cancel}
                            required
                          />
                        </div>
                        <div className="pb-2 pl-2 my-2 d-flex justify-content-center">
                          <button
                            disabled={btnDisable}
                            className="btn btn-primary text-center text-dark px-3 text-uppercase rounded-sm"
                            type="submit"
                          >
                            {_t(t("Submit"))}
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                )}

                <table className="table table-striped table-sm text-center mt-3">
                  <thead className="bg-info text-white text-uppercase">
                    <tr>
                      <th scope="col" colSpan="2">
                        {_t(t("Order details"))}
                        {console.log("check order details", checkOrderDetails)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-capitalized">{_t(t("Customer"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.customer_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Contact"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.delivery_phn_no}
                      </td>
                    </tr>
                    
                    {
                      (checkOrderDetails.item?.alt_phn_no) && (
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Alt Phone No"))}
                          </td>
                          <td>
                            {checkOrderDetails.item &&
                              checkOrderDetails.item?.alt_phn_no || "-"}
                          </td>
                        </tr>
                      )
                    }

                    {
                        (checkOrderDetails.item?.ntn_no) && (
                         <tr>
                          <td className="text-capitalized">
                            {_t(t("NTN No"))}
                          </td>
                          <td>
                            {checkOrderDetails.item?.ntn_no || "-"}
                          </td>
                        </tr>
                        )
                    }

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Delivery Address"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.delivery_address}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Nearest Landmark"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.nearest_landmark || "-"}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Note to rider"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.note_to_rider}
                      </td>
                    </tr>
                   
                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Ref No"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item?.ref_no}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Payment Method"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.payment_method}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Punch Time"))}
                      </td>
                      <td>
                        <Moment format="LLL">
                          {checkOrderDetails.item && checkOrderDetails.item.created_at}
                        </Moment>
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Time To Deliver"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                        checkOrderDetails.item.time_to_deliver !== null
                          ? checkOrderDetails.item.time_to_deliver + " min(s)"
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Branch"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.branch_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Subtotal"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.order_bill)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>
                    {checkOrderDetails.item &&
                    checkOrderDetails.item.vat_system === "igst" ? (
                      <tr>
                        {/* <td className="text-capitalized">{_t(t("Igst"))}</td> */}
                        <td className="text-capitalized">{checkOrderDetails.item.theBranch?.print_tax}</td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(checkOrderDetails.item.vat)}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <>
                        <tr>
                          <td className="text-capitalized">{_t(t("CGST"))}</td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(parseFloat(0))}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-capitalized">{_t(t("SGST"))}</td>
                          <td>
                            {checkOrderDetails.item && (
                              <>
                                {currencySymbolLeft()}
                                {formatPrice(parseFloat(0))}
                                {currencySymbolRight()}
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Delivery Charges"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(
                              checkOrderDetails.item.delivery_charge
                            )}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Total bill"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.total_payable)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>
                    {checkOrderDetails.item &&
                      parseInt(checkOrderDetails.item.is_cancelled) === 1 && (
                        <tr>
                          <td className="text-capitalized">
                            {_t(t("Cancellation Reason"))}
                          </td>
                          <td>{checkOrderDetails.item.reason_of_cancel}</td>
                        </tr>
                      )}

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Delivery Man Status"))}
                      </td>
                      <td className="text-uppercase">
                        {checkOrderDetails.item &&
                        checkOrderDetails.item.delivery_status !== null
                          ? checkOrderDetails.item.delivery_status
                          : _t(t("Not Assigned"))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Settle modal Ends*/}
      
      {/* Change branch modal */}
      <div className="modal fade" id="changeBranch" aria-hidden="true">
        <div className="modal-dialog modal-md modal-dialog-centered">
            <form className="modal-content" onSubmit={(e)=>changeBranchSave(e)}>
              <div className="modal-header align-items-center">
                <h5 className="modal-title">                  
                  {_t(t("Change Branch"))}                  
                </h5>
                <button
                  type="button"
                  id="close-btn-branch"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <Select
                  options={branchForSearch}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  classNamePrefix="select"
                  className="table-text"
                  value={changeBranch?.branch}
                  // styles={customStyle}
                  onChange={(branch)=>setChangeBranch({...changeBranch,branch})}
                  maxMenuHeight="200px"
                  placeholder={_t(t("Select a branch")) + ".."}
                  // isClearable
                />
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary" disabled={loader}>{loader ? <div class="spinner-border text-light spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div> : "Save"}</button>
                {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Ok</button> */}
              </div>            
            </form>
        </div>
      </div>
      {/* Change branch modal end */}

      {/* Change status modal */}
      <div className="modal fade" id="changeStatus" aria-hidden="true">
        <div className="modal-dialog modal-md modal-dialog-centered">
            <form className="modal-content" onSubmit={(e)=>changeStatusSave(e)}>
              <div className="modal-header align-items-center">
                <h5 className="modal-title">                  
                  {_t(t("Change Status"))}                  
                </h5>
                <button
                  type="button"
                  id="close-btn-status"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body w-100">
                <div className="mb-1 modal-scroll" style={{height:"8.75rem"}}>
                  <div className="row m-0">
                    {
                      orderStatus && orderStatus.map((status,index) =>{
                        return (
                          <div className={`col-6 mb-1 text-break ${index % 2 === 0 ? "pl-0" : "pr-0"}`}>
                            <button type="button" className={`w-100 rounded-sm btn btn-sm ${status.id === changeStatus?.status?.id ? "btn-success" : "btn-primary"} table-text`} onClick={()=> setChangeStatus({...changeStatus,status: status.id === changeStatus?.status?.id ? null : status })}>
                              {status.description}
                            </button>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="d-block mt-2">
                  <input
                    required={changeStatus?.status && (changeStatus.status?.description.toLowerCase() === "cancelled" || changeStatus.status?.description.toLowerCase() === "cancel")}                  
                    type="text"
                    className="form-control table-text w-30"
                    id="reason"
                    name="reason"
                    onChange={(e)=>setChangeStatus({...changeStatus,remarks:e.target.value})}
                    value={changeStatus.remarks}
                    placeholder="Reason"                  
                    autoComplete="off"                  
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary" disabled={loader}>{loader ? <div class="spinner-border text-light spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div> : "Save"}</button>                
              </div>            
            </form>
        </div>
      </div>
      {/* Change status modal end */}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {checkOrderDetails.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                   {/* <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedOrder.searched
                              ? _t(t("Online / Call Center orders"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                    </div> */}
                    <div className="col-md-6 col-lg-6 t-mb-15 mb-md-0"
                    style={{ borderRight: "1px solid #6c757da6" }}
                    >
                      <ul className="t-list fk-sort align-items-center mr-3">
                      <li className="fk-breadcrumb__list mr-3">
                      <span className="t-link fk-breadcrumb__link text-capitalize">
                      {!searchedOrder.searched
                        ? _t(t("Online / Call Center orders"))
                        : _t(t("Search Result"))}
                      </span>
                      </li>
                        <div className="input-group col">
                          <div className="form-file">
                            <input
                              type="text"
                              className="form-control border-0 form-control--light-1 rounded-left"
                              placeholder={
                                _t(t("Search by token, customer, branch")) +
                                ".."
                              }
                              onChange={handleSearch}
                            />
                          </div>
                          <button
                            className="btn btn-primary rounded-right"
                            type="button"
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </button>
                        </div>
                      </ul>
                    </div>
                    <div className="col-md-4 col-lg-4">
                    <div className="d-flex px-4 align-items-center gx-2">
                    {
                      availableDeliveryTypes && availableDeliveryTypes?.map((orderType)=>{
                        return(
                          <div className="mr-3">
                          <input
                          className={`submit-filter ${activebtn === orderType.name ? "active-btn" : ""} `}
                          id = {orderType.name}
                          name = {orderType.name}
                          value= {orderType.name}
                          type = "submit"
                          onClick={(e) => {
                            setActivebtn(e.target.name);
                            handleSearch(e)
                          }}
                          />
                        </div>
                        )
                      })
                    }
                      <div className="mr-3">
                        <input
                         className={`submit-filter ${activebtn === "all" ? "active-btn" : ""} `}
                         id="all"
                         name="all"
                         value="All"
                         type="submit"
                         onClick={(e) =>{ 
                          setActivebtn(e.target.name)
                          handleSearch(e)
                         }}
                        />
                      </div>
                     </div>
                    </div>
                    <div className="col-md-2 col-lg-2">
                      <div className="row align-items-center gx-2">
                        <div className="col-6 ">
                          <NavLink
                            to="/dashboard/pos"
                            className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-secondary xsm-text text-uppercase text-center w-100 rounded"
                          >
                            {_t(t("POS"))}
                          </NavLink>
                        </div>
                        {authUserInfo?.details?.user_type ===
                        "call_center" ? null : (
                          <div className="col-6">
                            <NavLink
                              to="/dashboard/pos/submitted"
                              className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-primary xsm-text text-uppercase text-center w-100 rounded"
                            >
                              {_t(t("Submitted"))}
                            </NavLink>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-settle" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                              >
                                {_t(t("Token"))}
                              </th>

                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                            >
                              {_t(t("Order Type"))}
                             </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Customer"))}
                              </th>

                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Customer Phone"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Status"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth:"230px"}}
                              >
                                {_t(t("Action"))}
                              </th>
                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Time"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Ref No"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Payment Method"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Username"))}
                              </th>                              
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {
                              /* loop here, logic === !search && haveData && haveDataLegnth > 0*/
                              console.log(
                                "searchedOrder",
                                searchedOrder,
                                onlineOrdersAdmin
                              )
                            }
                            {!searchedOrder.searched
                              ? [
                                  onlineOrdersAdmin && [
                                    onlineOrdersAdmin.data.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="8"
                                          className="table-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      onlineOrdersAdmin.data.map(
                                        (item, index) => {
                                          return itemListContent(item,index)
                                        }
                                      )
                                    ),
                                  ],
                                ]
                              : [
                                  /* searched data, logic === haveData*/

                                  searchedOrder && [
                                    searchedOrder.list.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="8"
                                          className="table-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedOrder.list.map((item, index) => {
                                        return itemListContent(item,index)
                                      })
                                    ),
                                  ],
                                ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* pagination loading effect */}
              {checkOrderDetails.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedOrder.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(
                                onlineOrdersAdmin,
                                setPaginatedOnlineOrders
                              )}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(onlineOrdersAdmin)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedOrder({
                                      ...searchedOrder,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedOrder,
                                    onlineOrdersAdminForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default OnlineOrders;
